import { useState } from "react";
import FontAwesome from "../FontAwesome";

const PassInput = (props) => {
    const [passwordVisible, setPasswordVisibility] = useState(false);

    function togglePasswordVisibility() {
        setPasswordVisibility(!passwordVisible);
      }

  return (
    <div className="row align-items-center">
      <div className="col">
        <input
          className={"form-control "+props.className}
          type={passwordVisible ? "text" : "password"}
          name={props.name}
          required={props.required}
        />
      </div>
      <div
        className="col-auto ps-0 cursor-pointer"
        onClick={togglePasswordVisibility}
      >
        <FontAwesome
          type="solid"
          icon={passwordVisible ? "eye-slash" : "eye"}
        />
      </div>
    </div>
  );
};

export default PassInput;