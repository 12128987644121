import Title from "../../../components/Layout/Title/Title";
import CategoriaNova from "./CategoriaNova";
import http from "../../../misc/classes/HttpRequest/HttpRequest";
import CategoriaLista from "./CategoriaLista";
import { useEffect } from "react";
import { ListServiceProvider } from "../../../components/shared/Lists/ListService";
import { useState } from "react";
import CategoriaSalvarPosicao from "./CategoriaSalvarPosicao";
import SearchList from "../../../components/shared/Lists/SearchList/SearchList";
import { useRef } from "react";

const Categorias = () => {
  const [list, setList] = useState([]);
  const modalNew = useRef();

  useEffect(() => {
    getCategorias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCategorias() {
    http.get("/categorias", onSuccess);
  }

  function onSuccess(data) {
    setList(data.list);
  }

  function openNewModal(){
    modalNew.current.open();
  }

  return (
    <>
      <Title notLink={true} type="function" id="nova-categoria" onClick={openNewModal} >
        Categorias
      </Title>

      <CategoriaNova ref={modalNew} onSuccess={getCategorias} />

      <div className="mt-5"></div>

      <ListServiceProvider
        list={list}
        searchIn={["name"]}
      >
        <SearchList />

        <div className="mb-2 mb-lg-4"></div>

        <CategoriaLista />
        <div className="d-block d-lg-inline-block my-5 text-center">
          <CategoriaSalvarPosicao />
        </div>
      </ListServiceProvider>
    </>
  );
};

export default Categorias;
