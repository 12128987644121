import { useEffect } from "react";
import useFirebase from "../../../../hooks/useFirebase";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import { useState } from "react";
import ActivesCard from "./ActivesCard";
import CSS from "./ActivesList.module.css";
import { useRef } from "react";
import ModalPedido from "../Pedido/ModalPedido/ModalPedido";
import Header from "../Header";

const ActivesList = (props) => {
  const { firebaseInit, database, databaseFunc } = useFirebase(["database"]);
  const [pending, setPending] = useState([]);
  const [preparing, setPreparing] = useState([]);
  const [delivering, setDelivering] = useState([]);
  const modalPedido = useRef();
  const audioRef = useRef();
  const firstCall = useRef(true);

  useEffect(() => {
    firebaseInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (database) {
      listerNewOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [database]);

  function listerNewOrders() {
    databaseFunc.getRef("listeners/orders/last", newOrder);
  }

  function newOrder(){
    triggerSoundEffect();
    getNewOrders();
  }

  function getNewOrders() {
    HttpRequest.get("/pedidos/actives", onSuccess);
  }

  function onSuccess(data) {
    setPending(data.pending);
    setPreparing(data.preparing);
    setDelivering(data.delivering);
  }

  function triggerSoundEffect() {    
    if(firstCall.current === true){
        firstCall.current = false;
        return;
    };

    audioRef.current.volume = 1;

    audioRef.current.play();
  }

  return (
    <>
      <Header />

      <div className="row">
        <div className={"col-12 col-md-4 mb-3 " + CSS.columns}>
          <div className="box-widget p-4 h-100">
            <h5
              className="text-info mb-4 text-uppercase"
              style={{ letterSpacing: "2px" }}
            >
              Pendentes
            </h5>

            {pending.map((pedido, index) => {
              return (
                <ActivesCard
                  pedido={pedido}
                  key={index}
                  onClick={modalPedido.current.open}
                />
              );
            })}
          </div>
        </div>
        <div className={"col-12 col-md-4 mb-3 " + CSS.columns}>
          <div className="box-widget p-4 h-100">
            <h5
              className="mb-4 text-uppercase"
              style={{ letterSpacing: "2px", color: "#7300ff" }}
            >
              Preparando
            </h5>

            {preparing.map((pedido, index) => {
              return (
                <ActivesCard
                  pedido={pedido}
                  key={index}
                  onClick={modalPedido.current.open}
                />
              );
            })}
          </div>
        </div>
        <div className={"col-12 col-md-4 mb-3 " + CSS.columns}>
          <div className="box-widget p-4 h-100">
            <h5
              className="text-primary mb-4 text-uppercase"
              style={{ letterSpacing: "2px" }}
            >
              Entrega
            </h5>

            {delivering.map((pedido, index) => {
              return (
                <ActivesCard
                  pedido={pedido}
                  key={index}
                  onClick={modalPedido.current.open}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className={CSS.hidden}>
        <audio ref={audioRef}>
          <source src="/sound/new-order.mp3" type="audio/mpeg" />
        </audio>
      </div>

      <ModalPedido ref={modalPedido} updateList={getNewOrders} />
    </>
  );
};

export default ActivesList;
