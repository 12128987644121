import { useRef } from "react";
import ButtonLoading from "../../../../components/Layout/ButtonLoading";
import Form from "../../../../components/shared/Facilitators/Form";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import { useEffect } from "react";

const Pagamentos = (props) => {
  const btnSave = useRef();
  const formRef = useRef();
  const onlineInputRef = useRef();
  
  useEffect(() => {
    getPagamentos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPagamentos() {
    HttpRequest.get("/configuracoes/pagamentos", fillPagamentos);
  }

  function fillPagamentos(response){
    if(response.pagamentos){
        onlineInputRef.current.value = response.pagamentos.online;
    };
  };

  function onSubmit(form) {
    btnSave.current.setLoading(true);
    HttpRequest.post("/configuracoes/pagamentos", form, onSuccess, onError);
  }

  function onSuccess() {
    btnSave.current.setLoading(false);
  }

  function onError() {
    btnSave.current.setLoading(false);
  }

  return (
    <>
      <Form className="box-widget" onSubmit={onSubmit} ref={formRef}>
        <h3 className="mb-4 fw-bold text-success">Pagamentos</h3>

        <div className="row mb-4">
          <div className="col-lg-2 col-12 fw-bold">Pagamento Online:</div>
          <div className="col-lg-4 col">
            <select
              name="online"
              ref={onlineInputRef}
              className="form-select w-auto"
            >
              <option value="1">Ativado</option>
              <option value="0">Desativado</option>
            </select>
          </div>
        </div>

        <div className="text-lg-start text-end mt-4">
          <ButtonLoading className="text-primary" ref={btnSave}>
            <button className="btn btn-primary">Salvar</button>
          </ButtonLoading>
        </div>
      </Form>
    </>
  );
};

export default Pagamentos;
