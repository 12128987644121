import { forwardRef, useRef } from "react";
import Modal from "../../../../../components/Layout/Modal/Modal";
import { useContext } from "react";
import ListService from "../../../../../components/shared/Lists/ListService";
import { useImperativeHandle } from "react";

const DeleteSubGroup = forwardRef((props, ref) => {
  const modal = useRef();
  const listService = useContext(ListService);
  const groupIndexRef = useRef();
  
  useImperativeHandle(ref, () => ({
    open
  }));

  function open(groupIndex){
    groupIndexRef.current.value = groupIndex;
    modal.current.open();
  }

  function confirmDeleteGroup() {
    let newList = listService.copy();

    newList.splice(groupIndexRef.current.value, 1);

    listService.change(newList);
    modal.current.close();
  }

  return (
    <>
      <Modal
        id="deletar-grupo"
        isStatic={true}
        title="Deletar subgrupo"
        ref={modal}
      >
        <input className="hidden" type="text" ref={groupIndexRef} />

        <div>Deseja realmente deletar este subgroupo de opções?</div>
        <div>Você perderá todas as opções dentro do subgrupo.</div>

        <div className="row mt-5 justify-content-between">
          <div className="col-auto">
            <div className="btn btn-secondary" data-bs-dismiss="modal">
              Voltar
            </div>
          </div>
          <div className="col-auto">
            <div className="btn btn-danger" onClick={confirmDeleteGroup}>
              Deletar
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default DeleteSubGroup;
