import { useRef } from "react";
import Form from "../../../../components/shared/Facilitators/Form";
import ButtonLoading from "../../../../components/Layout/ButtonLoading";
import TelInput from "../../../../components/Layout/Forms/TelInput";
import DocumentInput from "../../../../components/Layout/Forms/DocumentInput";
import { useState } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import Modal from "../../../../components/Layout/Modal/Modal";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";

const ClienteInformacoes = ({ customer }) => {
  const btnSave = useRef();
  const modalLockEmail = useRef();
  const [lockEmail, setLockEmail] = useState(true);

  function onSubmit(data) {
    btnSave.current.setLoading(true);

    HttpRequest.post("/clientes/" + customer.id, data, onSuccess);
  }

  function onSuccess(response) {
    btnSave.current.setLoading(false);
  }

  function toogleLockEmail() {
    if (lockEmail) {
      modalLockEmail.current.open();
      return;
    }

    setLockEmail(!lockEmail);
  }

  function forceUnlockEmail() {
    modalLockEmail.current.close();
    setLockEmail(false);
  }

  return (
    <>
      <Form
        className="box-widget m-n-content-mobile"
        onSubmit={onSubmit}
        method="PUT"
      >
        <div className="row">
          <div className="col-lg-1 col-12">
            <div className="form-group">
              <label htmlFor="name">ID</label>
              <input
                type="text"
                readOnly
                disabled
                className="form-control"
                defaultValue={customer.id}
              />
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <label htmlFor="name">Nome</label>
              <input
                id="name"
                name="name"
                type="text"
                required
                className="form-control"
                defaultValue={customer.name}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg col-12">
            <div className="form-group">
              <label htmlFor="name">Email</label>
              <div className="row">
                <div className="col">
                  <input
                    type="email"
                    name="email"
                    readOnly={lockEmail}
                    disabled={lockEmail}
                    defaultValue={customer.email}
                    className="form-control"
                  />
                </div>
                <div className="col-auto ps-0">
                  <div
                    className="btn btn-light text-primary"
                    onClick={toogleLockEmail}
                  >
                    {lockEmail && <FontAwesome type="solid" icon="lock" />}
                    {!lockEmail && (
                      <FontAwesome type="solid" icon="lock-open" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col">
                  <label>Telefone</label>
                </div>
                <div className="col-auto d-none d-lg-block">
                  <a
                    href={"https://api.whatsapp.com/send?phone=" + customer.tel}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-light text-success fsc-n8 py-1 fw-bold"
                  >
                    Abrir no Whatsapp
                  </a>
                </div>
              </div>
              <TelInput
                defaultValue={customer.tel}
                name={"tel"}
                className="form-control"
                inputProps={{ required: true }}
              />
              <div className="d-lg-none mt-2 text-end">
                <a
                  href={"https://api.whatsapp.com/send?phone=" + customer.tel}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-light text-success fsc-n8 py-1 fw-bold"
                >
                  Abrir no Whatsapp
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <label htmlFor="name">CPF/CNPJ</label>
              <DocumentInput
                defaultValue={customer.document}
                name={"document"}
                className="form-control"
                inputProps={{ required: true }}
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-lg-auto col-12 mb-3 mb-lg-0">
            <ButtonLoading ref={btnSave} className="text-primary">
              <button
                type="submit"
                className="btn btn-primary fw-bold w-100 px-4"
              >
                Salvar
              </button>
            </ButtonLoading>
          </div>
        </div>
      </Form>

      <Modal ref={modalLockEmail} id="lock-email-customer-modal">
        <div className="p-4 text-center">
          <div className="fsc-5 text-success">
            <FontAwesome type="solid" icon="person-circle-exclamation" />
          </div>

          <div className="fsc-2 fw-bold">Atenção!</div>

          <div className="fw-light my-5">
            <p>Alterar o e-mail irá interferir no acesso do cliente ao site.</p>
            <p>
              Tenha certeza de estar efetuando esta alteração com o
              consentimento do cliente.
            </p>
          </div>

          <div
            className="w-100 btn btn-secondary fw-bold py-3"
            onClick={forceUnlockEmail}
          >
            Eu entendo
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ClienteInformacoes;
