import { useContext } from "react";
import FontAwesome from "../../../Layout/FontAwesome";
import CSS from "./SearchList.module.css";
import ListService from "../ListService";
import { useRef } from "react";

const SearchList = () => {
  const listService = useContext(ListService);
  const inputRef = useRef();
  const inputMobileRef = useRef();

  function searchIt(e) {
    e.preventDefault();

    const tag = e.target.tagName;
    let value;

    if (tag === "FORM") {
      value = inputMobileRef.current.value;
    }

    if (tag === "INPUT") {
      value = inputRef.current.value;
    }

    listService.search(value);
  }

  function clearSearch() {
    inputMobileRef.current.value = null;
    inputRef.current.value = null;
    listService.search("");
  }

  return (
    <>
      <div className={"d-none d-lg-block " + CSS.search_list}>
        <input
          className="form-control"
          type="text"
          placeholder="Pesquisar"
          ref={inputRef}
          onChange={searchIt}
        />
        <div className={CSS.icon}>
          <FontAwesome icon="magnifying-glass" type="solid" />
        </div>
        <div title="Limpar" className={CSS.cancel} onClick={clearSearch}>
          <FontAwesome icon="times" type="solid" />
        </div>
      </div>

      <div className={CSS.search_mobile + ' m-n-content-mobile'}>
        <div
          className={
            CSS.search_list +
            " d-lg-none"
          }
        >
          <form onSubmit={searchIt}>
            <input
              className="form-control"
              placeholder="Pesquisar"
              type="text"
              inputMode="search"
              ref={inputMobileRef}
            />
            <div className={CSS.icon}>
              <FontAwesome icon="magnifying-glass" type="solid" />
            </div>
            <div title="Limpar" className={CSS.cancel} onClick={clearSearch}>
              <FontAwesome icon="times" type="solid" />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SearchList;
