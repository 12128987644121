import Price from "../../../../../misc/classes/Filters/Price";

const ItemsInfo = ({ pedido }) => {
  return (
    <>
      <div className="items">
        <h5 className="text-uppercase mb-3 text-secondary">Itens</h5>

        <div className="fsc-n9 opacity-6">
          <div className="row">
            <div className="col-auto ps-4">QTD</div>
            <div className="col ps-4">ITEM</div>
            <div className="col-auto pe-4">VALOR</div>
          </div>
        </div>

        <div className="fsc-1p2 fsc-m-1 mb-4">
          {pedido.items.map((item, indexItem) => {
            return (
              <div className="rounded border p-3 my-3" key={indexItem}>
                <div className="row fw-500" key={indexItem}>
                  <div className="col-auto">
                    <div
                      className="rounded py-1 px-2"
                      style={{
                        backgroundColor: "var(--bs-gray-200)",
                      }}
                    >
                      {item.quantity}
                    </div>
                  </div>
                  <div className="col pt-1">
                    <div>{item.name}</div>

                    <div className="opacity-7 fsc-n8 mt-1 fw-normal">
                      {item.ingredients}
                    </div>

                    {item.observations && (
                      <div className="opacity-7 fsc-n7 mt-1 fw-normal">
                        {item.observations}
                      </div>
                    )}
                  </div>
                  <div className="col-auto">
                    {Price.numberToCurrency(item.quantity * item.price)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ItemsInfo;
