import Horarios from "./Horarios/Horarios";
import Pagamentos from "./Pagamentos/Pagamentos";

const Configuracoes = (props) => {
  return (
    <>
      <h1 className="text-center text-lg-start">Configurações</h1>

      <div className="py-2"></div>

      <Pagamentos />

      <div className="my-4"></div>

      <Horarios />
    </>
  );
};

export default Configuracoes;
