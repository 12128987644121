const LoadingPage = ({condition, children}) => {
   return (
       <>
           {!condition && (
                <div className="text-center mt-5" style={{color: 'var(--verde)'}}>
                    <div className="spinner-border fsc-1p2" role="status">
                        <span className="visually-hidden">Carregando...</span>
                    </div>
                </div>
           )}
           {condition && (
                children
           )}
       </>
   )
}

export default LoadingPage;