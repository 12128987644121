import { useNavigate } from "react-router-dom";
import { createContext, useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import SessionService from "../Session/Session";

const AuthService = createContext({
  logged: false,
  firstCall: true,
  username: null,
  login: ({ jwt, username, password }, keepLogin) => {},
  logout: () => {},
});
export default AuthService;

export function AuthServiceProvider(props) {
  const [auth, setAuthState] = useState(false);
  const [firstCall, setFirstCall] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [initialURL, setInitialURL] = useState(null);
  const [username, setUsernameState] = useState(null);
  const sessionCookie = useContext(SessionService);

  useEffect(() => {
    const path = location.pathname === null ? "/" : location.pathname;

    setInitialURL(path);

    loginBySessionOrJWT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loginBySessionOrJWT() {
    const JWT = localStorage.getItem("jwt_credential");

    login({ jwt: JWT }).then(() => {
      navigate(initialURL, { replace: true });
    });
  }

  function login({ jwt, username, password }, keepLogin = false) {
    const session_id = sessionCookie.get();
    const headers = {};

    if(session_id){
        headers['X-Session-Id'] = session_id;
    }

    return fetch(process.env.REACT_APP_API_URL + "/auth", {
      method: "POST",
      body: JSON.stringify({ jwt, username, password }),
      headers: headers
    })
      .then((response) => {
        if (firstCall) {
            setFirstCall(false);
        };

        return response
        .json()
        .then((data)=>{
            if(response.ok){
                onSuccessLogin(data, keepLogin);    
            } else {
                logout(false);
            }

            return data;
        });
      });
  }

  function onSuccessLogin(data, keepLogin) {
    setAuthState(true);
    setUsernameState(data.username);
    if(data.session_id) sessionCookie.set(data.session_id);

    if (keepLogin) saveJWT(data.jwt);
  }

  function requestLogout() {
    const session_id = sessionCookie.get();
    const headers = {};

    if(session_id){
        headers['X-Session-Id'] = session_id;
    }

    return fetch(process.env.REACT_APP_API_URL + "/auth", {
      method: "DELETE",
      headers: headers
    });
  }

  function logout($skipRequest = true) {
    if ($skipRequest) {
      requestLogout().then(() => {
        setAuthState(false);
        sessionCookie.delete();
        clearJWT();
      });
    } else {
      setAuthState(false);
      sessionCookie.delete();
      clearJWT();
    }
  }

  function saveJWT(jwt) {
    localStorage.setItem("jwt_credential", jwt);
  }

  function clearJWT() {
    localStorage.removeItem("jwt_credential");
  }

  const context = {
    logged: auth,
    firstCall: firstCall,
    username: username,
    login: login,
    logout: logout,
  };

  return (
    <AuthService.Provider value={context}>
      {props.children}
    </AuthService.Provider>
  );
}
