import { forwardRef } from "react";
import FontAwesome from "../../../../../components/Layout/FontAwesome";
import Form from "../../../../../components/shared/Facilitators/Form";
import EntregaFilter from "./EntregaFilter";
import OrderingFilter from "./OrderingFilter";
import PeriodFilter from "./PeriodFilter";
import StatusFilter from "./StatusFilter";
import { useImperativeHandle } from "react";
import { useState } from "react";
import { useEffect } from "react";

const Filters = forwardRef(({ onFilter, firstCall }, ref) => {
  const [filters, setFilters] = useState(null);

  useImperativeHandle(ref, () => ({
    fetchParams,
  }));

  useEffect(() => {
    if (!firstCall) onFilter(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function fetchParams() {
    return filters;
  }

  function onSubmit(form) {
    const formValues = Object.fromEntries(form.entries());
    setFilters(parseParam(formValues));
  }

  function parseParam(formValues) {
    let param = [];

    for (const filter in formValues) {
      param.push(filter + "=" + formValues[filter]);
    }

    param = param.join("&");

    return param;
  }

  return (
    <>
      <Form onSubmit={onSubmit} className="box-widget-table p-4">
        <div
          className="row cursor-pointer bg-light py-2 mx-0 rounded"
          data-bs-toggle="collapse"
          data-bs-target="#collapse_filters"
        >
          <div className="col text-secondary text-uppercase fw-500">
            <FontAwesome icon="filter" /> Filtros
          </div>
          <div className="col-auto">
            <FontAwesome icon="chevron-down" />
          </div>
        </div>
        <div id="collapse_filters" className="collapse show">
          <div className="row mt-3">
            <div className="col-lg-3 col-12">
              <OrderingFilter />
            </div>
            <div className="col-lg-3 col-12">
              <PeriodFilter />
            </div>
            <div className="col-lg-3 col-12 mb-3">
              <EntregaFilter />
            </div>
            <div className="col-12">
              <StatusFilter />
            </div>
          </div>

          <div className="row justify-content-end mt-4 mt-lg-0">
            <div className="col col-lg-auto">
              <button className="btn btn-primary fw-500 px-4 text-uppercase w-100">
                Filtrar
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
});

export default Filters;
