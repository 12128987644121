import { useRef, useState } from "react";
import FontAwesome from "../../Layout/FontAwesome";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useEffect } from "react";

/**
 * @param {string} name
 * @param {string} defaultValue
 * @param {JSX} children
 * @param {string} getUrl
 */
const ImageAutoUpload = forwardRef(
  ({ children, defaultValue, name, ingredient }, ref) => {
    const [image, setImage] = useState();

    const inputFileRef = useRef();

    useImperativeHandle(ref, () => ({
      getUrl,
      getTrimUrl,
      value,
    }));

    useEffect(() => {
      if (defaultValue) {
        if(ingredient){
            setImage('/produtos/ingredientes/'+defaultValue);
        } else {
            setImage('/produtos/'+defaultValue);
        }
      }
    }, [defaultValue, ingredient]);

    function getUrl() {
      return image;
    }

    function getTrimUrl(){
        let url = getUrl();

        if(url){
            if(url.indexOf('/produtos') > -1){
                url = url.replace('/produtos', '');
            };
            
            if(url.indexOf('/ingredientes') > -1){
                url = url.replace('/ingredientes/', '');
            };
        };

        return url;
    }

    function value(value) {
      setImage(value);
    }

    function triggerUpload() {
      inputFileRef.current.click();
    }

    function imageUpload(event) {
      if (!uploadHasFile(event)) {
        return;
      }

      const file = event.target.files[0];
      let url = window.URL || window.webkitURL;
      let image = new Image();

      image.onload = () => {
        getTempURL(file);
      };

      image.src = url.createObjectURL(file);
    }

    function uploadHasFile(event) {
      return event.target.files && event.target.files[0];
    }

    function getTempURL(file) {
      const formData = new FormData();
      formData.append("file", file);

      HttpRequest.post("/upload/image", formData, setTempURL);
    }

    function setTempURL(data) {
      setImage(data.url);
    }

    function removeUpload(event) {
      event.stopPropagation();
      setImage(null);
    }

    return (
      <>
        <div
          onClick={triggerUpload}
          className="position-relative"
          style={{ cursor: "pointer" }}
        >
          {!image && children}

          {image && (
            <div className="position-relative d-inline-block">
              <img src={process.env.REACT_APP_URL + image} alt="" />

              <div
                className="position-absolute d-inline-block"
                style={{ bottom: "10px", right: "10px" }}
              >
                <div
                  className="btn btn-light text-danger"
                  onClick={removeUpload}
                >
                  <FontAwesome icon="trash-can" type="solid" />
                </div>
              </div>
            </div>
          )}

          <input
            type="file"
            ref={inputFileRef}
            accept="image/*"
            className="hidden"
            onChange={imageUpload}
          />

          {name && (
            <input
              className="hidden"
              type="text"
              name={name}
              defaultValue={image}
            />
          )}
        </div>
      </>
    );
  }
);

export default ImageAutoUpload;
