class HttpRequest {
  static call(url, method, body = null, success, error) {
    const headers = {};

    const id = this.getCookie();

    if(id){
        headers['X-Session-Id'] = id;
    }

    return fetch(process.env.REACT_APP_API_URL + url, {
      method: method,
      body: body,
      headers: headers
    })
    .then((response)=> response.json())
    .then((data)=>{
        if(data.status >= 200 && data.status < 300){
            success(data);
        } else {
            error(data);
        };
    })
    .catch((error)=>{
        if(process.env.NODE_ENV !== 'production') console.log(error);
    })
  }

  static post(url, body, success = null, error = null) {
    return this.call(url, "POST", body, success, error);
  }

  static get(url, success = null, error = null) {
    return this.call(url, "GET", null, success, error);
  }
  
  static put(url, body, success = null, error = null) {
    body = this.formdata_to_json(body);

    return this.call(url, "PUT", body, success, error);
  }
  
  static delete(url, success = null, error = null) {
    return this.call(url, "DELETE", null, success, error);
  }

  static formdata_to_json(formData){
    const object = {};

    formData.forEach((value, key)=>{
        object[key] = value;
    });

    return JSON.stringify(object);
  }

  static getCookie(cookieName = 'session_id') {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].split('=');
      if (cookie[0] === cookieName) {
        return decodeURIComponent(cookie[1]);
      }
    }
    return null;
  }
}

export default HttpRequest;
