import Nav from "../../components/Layout/Nav/Nav";
import RoutesList from "../Routes";
import CSS from './OutletPainel.module.css';

const OutletPainel = () => {
  return (
    <>
      <Nav />
      <div className={CSS.outlet}>
        <div className="p-lg-5 p-4">
            <div className="pt-lg-4">
                <RoutesList />
            </div>
        </div>
      </div>
    </>
  );
};

export default OutletPainel;
