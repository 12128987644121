import { createContext } from "react";
import { useCookies } from "react-cookie";

const SessionService = createContext({
  set: (sessionId) => {},
  get: () => {},
  delete: () => {}
});
export default SessionService;

export function SessionServiceProvider(props) {
    const [cookies, setCookie, removeCookie] = useCookies(['session_id']);

    const setSessionId = (session_id) => {
        setCookie('session_id', session_id);
    }
    
    const getSessionId = () => {
        return cookies.session_id;
    }

    const deleteSessionId = () => {
        removeCookie('session_id');
    }

const context = {
    set: setSessionId,
    get: getSessionId,
    delete: deleteSessionId
}
    
  return (
    <SessionService.Provider value={context}>
      {props.children}
    </SessionService.Provider>
  );
}
