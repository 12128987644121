import Header from "../Header";
import FullList from "./FullList";

const AllOrders = (props) => {
  return (
    <>
      <Header />

      <FullList />
    </>
  );
};

export default AllOrders;
