import { useContext, useRef, useState } from "react";
import FontAwesome from "../../components/Layout/FontAwesome";
import ButtonLoading from "../../components/Layout/ButtonLoading";
import AuthService from "../../services/Auth/Auth";
import CSS from "./Entrar.module.css";

const Entrar = () => {
  const authService = useContext(AuthService);
  const [passwordVisible, setPasswordVisibility] = useState(false);
  const [error, setError] = useState(0);
  const inputUsername = useRef();
  const inputPassword = useRef();
  const inputKeepLogin = useRef();
  const btnSubmit = useRef();

  function togglePasswordVisibility() {
    setPasswordVisibility(!passwordVisible);
  }

  function onSubmit(e) {
    e.preventDefault();

    const body = {
      username: inputUsername.current.value,
      password: inputPassword.current.value,
    };

    btnSubmit.current.setLoading(true);
    setError(0);

    authService
      .login(body, inputKeepLogin.current.checked)
      .then((data) => {
        if (data.status !== 200) {
          setError(data.status);
        }

        btnSubmit.current.setLoading(false);
      })
      .catch(() => {
        setError(403);
        btnSubmit.current.setLoading(false);
      });
  }

  return (
    <>
      <div
        className="d-flex w-100 align-items-center justify-content-center bg-light flex-column"
        style={{ minHeight: window.innerHeight + "px" }}
      >
        <div className={"p-5 border shadow rounded-5 " + CSS.box}>
          <div className="py-4"></div>

          <div className="px-5 pb-3">
            <div className="px-3 text-center">
              <img
                alt="logo"
                src="img/logo/logo-full-medium.png"
                className={"w-100 " + CSS.logo_menu}
              />
            </div>
          </div>

          <form className="mt-5" onSubmit={onSubmit}>
            <div className="form-group mb-4">
              <div className="fw-bold">Usuário</div>
              <input
                className="form-control py-2"
                type="text"
                name="username"
                ref={inputUsername}
              />
            </div>

            <div className="form-group">
              <div className="fw-bold">Senha</div>

              <div className="row align-items-center">
                <div className="col">
                  <input
                    className="form-control py-2"
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    ref={inputPassword}
                  />
                </div>
                <div
                  className="col-auto ps-0 cursor-pointer"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesome
                    type="solid"
                    icon={passwordVisible ? "eye-slash" : "eye"}
                  />
                </div>
              </div>
            </div>

            <div className="py-3">
              <input
                type="checkbox"
                name="keep_login"
                id="keep_login"
                ref={inputKeepLogin}
              />
              <label className="ms-2" htmlFor="keep_login">
                Manter-se logado
              </label>
            </div>

            {error !== 0 && (
              <div className="py-3">
                <div
                  className="alert alert-danger alert-dismissible mb-0"
                  role="alert"
                >
                  <FontAwesome
                    type="solid"
                    icon="circle-exclamation"
                    className="me-2"
                  />
                  <span className="ms-2">
                    {error === 401
                      ? "Usuário ou senha incorretos."
                      : "Desculpe, ocorreu um erro. Tente novamente mais tarde."}
                  </span>
                </div>
              </div>
            )}

            <div className="text-center mt-4">
              <ButtonLoading ref={btnSubmit} className="text-primary">
                <button className="btn btn-primary w-100 fw-bold py-2">
                  Entrar
                </button>
              </ButtonLoading>
            </div>
          </form>

          <div className="py-2"></div>
        </div>
        <div className="my-5 fsc-1p2 fw-light">
          Desenvolvido por{" "}
          <a
            href="https://agencialeta.com.br"
            alt=""
            target="_blank"
            rel="noreferrer"
          >
            Agência Aleta
          </a>{" "}
        </div>
      </div>
    </>
  );
};

export default Entrar;
