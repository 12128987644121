const OrderingFilter = (props) => {
   return (
       <>
           <div className="form-group mb-3">
            <label>Ordernar</label>
            <select className="form-select" name='order'>
                <option value='created_at-desc'>Mais novos</option>
                <option value='created_at-asc'>Mais antigos</option>
                <option value='total-desc'>Maiores valores</option>
                <option value='total-asc'>Menores valores</option>
                <option value='status-asc'>Status</option>
            </select>
           </div>
       </>
   )
}

export default OrderingFilter;