import { useContext } from "react";
import ListService from "../../../../components/shared/Lists/ListService";
import { useNavigate } from "react-router-dom";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import StatusTags from "../Templates/StatusTags";
import DeliveryTags from "../Templates/DeliveryTags";
import PaymentTag from "../Templates/PaymentTag";

const PedidosLista = (props) => {
  const list = useContext(ListService);
  const navigate = useNavigate();

  function goToOrder(id) {
    const id_ = id.toString();
    navigate("/pedidos/" + id_);
  }

  return (
    <>
      <div className="m-n-content-mobile fsc-m-n8">
        <div className="box-widget-table ">
          <div className="table-responsive">
            <table className="table table-style table-hover table-striped">
              <thead className="fw-bold fsc-n8">
                <tr>
                  <td className="text-center">ID</td>

                  <td className="text- px-4">DATA</td>

                  <td className="text- px-4">STATUS</td>

                  <td className="text- px-4">ENTREGA</td>

                  <td className="text- px-4">PAGAMENTO</td>

                  <td className="px-4">CLIENTE</td>

                  <td>TOTAL</td>

                  <td></td>
                </tr>
              </thead>
              <tbody className="table-group-divider align-middle bg-white border-top">
                {list.list.length > 0 &&
                  list.list.map((order, index) => {
                    return (
                      <tr
                        key={index}
                        className="cursor-pointer"
                        onClick={goToOrder.bind(null, order.id)}
                      >
                        <td className="cell-min px-4">{order.id}</td>

                        <td className="cell-min px-4 fsc-n9 text-secondary fw-light">{order.date}</td>

                        <td className="cell-min px-4">
                          <StatusTags status={order.status} address={order.address} />
                        </td>

                        <td className="cell-min px-4">
                          <DeliveryTags address={order.address} />
                        </td>

                        <td className="cell-min px-4">
                          <PaymentTag payment={order.payment_method} />
                        </td>

                        <td className="py-lg-4 py-3 px-4">{order.name}</td>

                        <td className="d-none d-lg-table-cell">
                          {order.total_formated}
                        </td>

                        <td
                          className="cell-min pe-lg-4 pe-3 fsc-1p2"
                          style={{ color: "var(--bs-gray-300)" }}
                        >
                          <FontAwesome type="solid" icon="chevron-right" />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PedidosLista;
