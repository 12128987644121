import { useState } from "react";
import CSS from "./Promocao.module.css";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import { NumericFormat } from "react-number-format";
import { useEffect } from "react";

const Promocao = forwardRef(({ defaultPrice, defaultDiscount }, ref) => {
  const [showBox, setShowBox] = useState(false);
  const percentageRef = useRef();
  const discountRef = useRef();
  const finalPriceRef = useRef();

  useImperativeHandle(ref, () => ({ toggle }));

  useEffect(() => {
    calcByPercentageDiscount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPrice]);

  useEffect(() => {
    if(defaultDiscount){
        setTimeout(()=>{
            discountRef.current.value = numberToText(defaultDiscount);
            calcByTotalDiscount();
        }, 200);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toggle(state) {
    setShowBox(state);
  }

  function textToNumber(priceText) {
    priceText = priceText.replace(".", "");
    priceText = priceText.replace(",", ".");
    return parseFloat(priceText);
  }

  function numberToText(priceNumber) {
    const options = {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    let formattedAmount = priceNumber.toLocaleString("pt-BR", options);
    formattedAmount = formattedAmount.replace("R$", "");
    formattedAmount = formattedAmount.trim();

    return formattedAmount;
  }

  function getDefaultPrice() {
    return typeof defaultPrice === "number"
      ? defaultPrice
      : textToNumber(defaultPrice);
  }

  function calcByPercentageDiscount() {
    let percentage = parseFloat(percentageRef.current.value);

    if (percentage === "NaN") {
      setInputValues();
      return;
    }

    percentage = percentage / 100;
    const discount = getDefaultPrice() * percentage;
    const finalPrice = getDefaultPrice() - discount;

    setInputValues({discount, finalPrice});
  }

  function calcByTotalDiscount() {
    let discount = textToNumber(discountRef.current.value);

    if (discount === "NaN") {
      setInputValues();
      return;
    }

    const percentage = discount / getDefaultPrice();
    const finalPrice = getDefaultPrice() - discount;

    setInputValues({percentage, finalPrice});
  }

  function calcByFinalPrice() {
    let finalPrice = textToNumber(finalPriceRef.current.value);

    if (finalPrice === "NaN") {
      setInputValues();
      return;
    }

    const discount = getDefaultPrice() - finalPrice;
    const percentage = discount / getDefaultPrice();

    setInputValues({percentage, discount});
  }

  function setInputValues(values) {
    if (values.percentage) {
      percentageRef.current.value = percentageFloor(values.percentage);
    }

    if (values.discount){
      discountRef.current.value = numberToText(values.discount);
    }

    if (values.finalPrice) {
      finalPriceRef.current.value = numberToText(values.finalPrice);
    }
  }

  function percentageFloor(percentage) {
    percentage = percentage * 100;
    return Math.floor(percentage);
  }

  return (
    <>
      <div
        className={"rounded p-3 mb-4 " + CSS.box + " " + (!showBox && "d-none")}
      >
        <div className="text-uppercase fw-bold text-secondary">Promoção</div>

        <div className="row mt-2 align-items-center mx-0">
          <div className="col-12 col-lg-4 py-2 bg-warning rounded mb-3 mb-lg-0">
            <div className="form-group mb-0">
              <label>Valor na Promoção</label>
              <div className="row align-items-center">
                <div className="col-auto pe-0">R$</div>
                <div className="col">
                  <NumericFormat
                    className="form-control"
                    displayType="input"
                    valueIsNumericString={true}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    fixedDecimalScale={true}
                    placeholder="0,00"
                    defaultValue={defaultPrice ? numberToText(defaultPrice) : 0}
                    inputMode="decimal"
                    getInputRef={finalPriceRef}
                    onChange={calcByFinalPrice}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="form-group mb-lg-0">
              <label>% de Desconto</label>
              <input
                type="text"
                inputMode="decimal"
                className="form-control"
                onChange={calcByPercentageDiscount}
                defaultValue={0}
                ref={percentageRef}
              />
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="form-group mb-lg-0">
              <label>Valor de Desconto</label>
              <div className="row align-items-center">
                <div className="col-auto pe-0">R$</div>
                <div className="col">
                  <NumericFormat
                    name={"discount"}
                    className="form-control"
                    displayType="input"
                    valueIsNumericString={true}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    fixedDecimalScale={true}
                    placeholder="0,00"
                    defaultValue={0}
                    inputMode="decimal"
                    getInputRef={discountRef}
                    onChange={calcByTotalDiscount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Promocao;
