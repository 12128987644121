import { useContext } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import ListService from "../../../components/shared/Lists/ListService";
import { useNavigate } from "react-router-dom";
import ProdutoFoto from "./ProdutoFoto";
import PriceDisplay from "./Promocao/PriceDisplay";
import TagNotVisible from "../../../components/Layout/Tags/TagNotVisible";
import TagDiscount from "../../../components/Layout/Tags/TagDiscount";
import UpAndDownList from "../../../components/shared/Lists/UpAndDownList";

const ProdutosLista = ({ noCategory, ordering }) => {
  const listService = useContext(ListService);
  const navigate = useNavigate();

  function goToProduct(id) {
    const id_ = id.toString();
    navigate("/produtos/" + id_);
  }

  return (
    <div className="m-n-content-mobile">
      <div className="box-widget-table">
        <div className="table-responsive">
          <table className="table table-style table-hover">
            <tbody className="table-group-divider align-middle bg-white border-top">
              {listService.list.length > 0 &&
                listService.list.map((product, index) => {
                  return (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={goToProduct.bind(null, product.id)}
                    >
                      {ordering === true && (
                        <td className="cell-min px-3">
                          <UpAndDownList index={index} />
                        </td>
                      )}
                      <td className="cell-min px-3 text-center">
                        <ProdutoFoto {...product} min={true} />
                      </td>
                      <td className="py-lg-4">
                        <div className="fw-bold">
                          {product.name}{" "}
                          {product.visible === 0 && <TagNotVisible />}
                          {product.discount > 0 && <TagDiscount />}
                        </div>
                        <div className="d-lg-none fsc-n7 row justify-content-between mt-2">
                          {!noCategory && (
                            <div className="col-auto">
                              {product.category_name}{" "}
                              {product.visible === 0 && <TagNotVisible />}
                              {product.discount > 0 && <TagDiscount />}
                            </div>
                          )}
                          <div className="col-auto">
                            <PriceDisplay product={product} />
                          </div>
                        </div>
                      </td>
                      {!noCategory && (
                        <td className="d-lg-table-cell d-none">
                          {product.category_name}
                        </td>
                      )}
                      <td className="fsc-m-n7 d-lg-table-cell d-none">
                        <PriceDisplay product={product} />
                      </td>
                      <td
                        className="cell-min pe-lg-4 pe-3 fsc-1p2"
                        style={{ color: "var(--bs-gray-300)" }}
                      >
                        <FontAwesome type="solid" icon="chevron-right" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProdutosLista;
