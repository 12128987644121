import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CSS2 from "../../Templates/Tags.module.css";
import FontAwesome from "../../../../../components/Layout/FontAwesome";

const StatusFilter = (props) => {
  const [statusChecked, setStatusChecked] = useState([
    "COMPLETED",
    "DELIVERING",
    "WAITING_DELIVER",
    "PREPARING",
    "ANALYZING",
    "PAID",
    "PROCESSING",
    "CANCELED",
  ]);
  const inputRef = useRef();

  useEffect(() => {
    writeInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChecked]);

  function onChanged(status) {
    let newStatusChecked = [...statusChecked];
    const index = statusChecked.indexOf(status);

    if (index > -1) {
      newStatusChecked.splice(index, 1);
    } else {
      newStatusChecked.push(status);
    }

    setStatusChecked(newStatusChecked);
  }

  function uncheckAll(){
    setStatusChecked([]);
  }
  
  function checkAll(){
    setStatusChecked([
        "COMPLETED",
        "DELIVERING",
        "WAITING_DELIVER",
        "PREPARING",
        "ANALYZING",
        "PAID",
        "PROCESSING",
        "CANCELED",
      ]);
  }

  function writeInput() {
    const statusList =
      statusChecked.length > 0 && statusChecked.length < optionsList.length
        ? statusChecked.join("+")
        : "";

    inputRef.current.value = statusList;
  }

  return (
    <>
      <div className="form-group mb-0">
        <label>Status</label>
        <input name="status" className="hidden" ref={inputRef} />
        <div className="p-0">
          {optionsList.map((status, index) => {
            return (
              <StatusFilterOption
                key={index}
                statusChecked={statusChecked}
                status={status}
                onChanged={onChanged}
              />
            );
          })}
        </div>
        <div>
            {statusChecked.length > 0 && <div className="mt-2 text-primary cursor-pointer fsc-n8 d-inline-block" onClick={uncheckAll}>Desmarcar todos</div> }
            {statusChecked.length === 0 && <div className="mt-2 text-primary cursor-pointer fsc-n8 d-inline-block" onClick={checkAll}>Marcar todos</div> }
        </div>
      </div>
    </>
  );
};

export default StatusFilter;

const StatusFilterOption = ({ status, onChanged, statusChecked }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(statusChecked.indexOf(status.value) > -1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChecked]);

  function toggleChecked() {
    // setChecked(!checked);
    onChanged(status.value);
  }

  return (
    <div
      className={
        "px-2 py-1 fsc-n9 opacity-5 d-inline-block border rounded fw-500 cursor-pointer m-1 " +
        (checked &&
          "opacity-9 " +
            CSS2[status.value === "COMPLETED" ? "PAID" : status.value])
      }
      onClick={toggleChecked}
    >
      <span className="me-2">
        {checked ? (
          <FontAwesome icon="square-check" />
        ) : (
          <FontAwesome type="regular" icon="square" />
        )}
      </span>
      {status.name}
    </div>
  );
};

const optionsList = [
  { value: "COMPLETED", name: "Concluído" },
  { value: "DELIVERING", name: "Rota de Entrega" },
  { value: "WAITING_DELIVER", name: "Aguardando Entregador/Retirada" },
  { value: "PREPARING", name: "Preparando" },
  { value: "ANALYZING", name: "Requer Análise" },
  { value: "PAID", name: "Pago" },
  { value: "PROCESSING", name: "Aguardando Pagamento" },
  { value: "CANCELED", name: "Cancelado" },
];
