import FontAwesome from "../../../../components/Layout/FontAwesome";
import StatusTags from "../Templates/StatusTags";
import CSS from './ActivesList.module.css';

const ActivesCard = ({ pedido, onClick }) => {
  function open() {
    onClick(pedido.id);
  }

  return (
    <>
      <div
        className={CSS.card + " rounded bg-light p-4 mb-3 box-shadow-hover cursor-pointer"}
        onClick={open}
      >
        <div className="row justify-content-between fsc-n9 fw-light">
          <div className="col-auto fw-bold text-success">
            <FontAwesome type="solid" icon="receipt" />{" "}
            <span className="ms-1">{pedido.id}</span>
          </div>
          <div className="col-auto">{pedido.date}</div>
        </div>

        <div className="fsc-1p2 fw-500 my-3 text-secondary">{pedido.name}</div>

        <div className="row justify-content-between">
          <div className="col-auto fw-light fsc-1p2">
            {pedido.total_formated}
          </div>
          <div className="col-auto">
            <StatusTags status={pedido.status} address={pedido.address} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivesCard;
