import { useContext } from "react";
import ListService from "../../../../components/shared/Lists/ListService";
import SubGrupo from "./SubGrupo";
import ModalOptionsService from "./Modals/ModalsProvider";

const SubGruposLista = ({ edit }) => {
  const listService = useContext(ListService);
  const modalOptionsService = useContext(ModalOptionsService);

  return (
    <>
      <div className="row mx-0">
        <div className="col col-lg-auto px-5 px-lg-4">
          <div
            className="btn btn-primary fsc-m-n8 w-100"
            onClick={modalOptionsService.addSubGroup}
          >
            Adicionar Grupo de Opções
          </div>
        </div>
      </div>

      {listService.list.map((subgroup, index) => {
        return <SubGrupo key={index} index={index} subgroup={subgroup} />;
      })}
    </>
  );
};

export default SubGruposLista;
