import FontAwesome from "../FontAwesome";

const TagDiscount = () => {
  return (
    <>
      <span title="Promoção" className="ms-3 fsc-n8 text-warning rounded bg-light p-2">
        <FontAwesome type="solid" icon="percentage" />{" "}
        <FontAwesome type="solid" icon="arrow-down" />
      </span>
    </>
  );
};

export default TagDiscount;
