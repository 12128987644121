import FontAwesome from "../../../../../components/Layout/FontAwesome";
import DocumentInput from "../../../../../components/Layout/Forms/DocumentInput";
import TelInput from "../../../../../components/Layout/Forms/TelInput";

const CustomerInfo = ({ pedido }) => {
  return (
    <>
      <div className="customer">
        <div className="row">
          <div className="col-lg col-12">
            <h5 className="text-uppercase mb-3 text-secondary">Cliente</h5>
          </div>
          <div className="col-lg-auto col-12">
            <a href={"/clientes/" + pedido.user} target="_blank" rel='noreferrer'>
              <div className="btn btn-light border fw-500 text-uppercase text-secondary">
                <FontAwesome icon="plus" /> Opções cliente
              </div>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-1 col-12">
            <div className="form-group">
              <label>ID</label>
              <input
                type="text"
                disabled
                className="form-control"
                defaultValue={pedido.user}
              />
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <label>Nome</label>
              <input
                type="text"
                defaultValue={pedido.name}
                className="form-control"
                disabled
              />
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col">
                  <label>Telefone</label>
                </div>
                <div className="col-auto d-none d-lg-block">
                  <a
                    href={"https://api.whatsapp.com/send?phone=" + pedido.tel}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-light text-success fsc-n8 py-1 fw-bold"
                  >
                    Abrir no Whatsapp
                  </a>
                </div>
              </div>
              <TelInput
                name=""
                className="form-control"
                defaultValue={pedido.tel}
                inputProps={{ disabled: true }}
              />
              <div className="d-lg-none mt-2 text-end">
                <a
                  href={"https://api.whatsapp.com/send?phone=" + pedido.tel}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-light text-success fsc-n8 py-1 fw-bold"
                >
                  Abrir no Whatsapp
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <label>CPF/CNPJ</label>
              <DocumentInput
                name=""
                className="form-control"
                defaultValue={pedido.document}
                inputProps={{ disabled: true }}
              />
            </div>
          </div>
          <div className="col-lg col-12">
            <div className="form-group">
              <label>E-mail</label>
              <input
                type="email"
                defaultValue={pedido.email}
                className="form-control"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerInfo;
