import { useRef } from "react";
import { useState } from "react";

const PeriodFilter = (props) => {
  const [customPeriod, setCustomPeriod] = useState(false);
  const startRef = useRef();
  const endRef = useRef();

  function toggleCustom(e){
    const value = e.target.value;
    if(value === 'custom'){
        setCustomPeriod(true);
    }
  }

  function backPredefined(){
    setCustomPeriod(false);
  }

  function defineStart(){
    const value = startRef.current.value;
    endRef.current.setAttribute('min', value);
  }

  function defineEnd(){
    const value = endRef.current.value;
    startRef.current.setAttribute('max', value);
  }

  return (
    <>
      <div className="form-group mb-3">
        <label>Período</label>

        {!customPeriod && (
          <>
            <select name="period" className="form-select" onChange={toggleCustom}>
                <option value='7'>7 Dias</option>
                <option value='15'>15 Dias</option>
                <option value='30'>1 Mês</option>
                <option value='90'>3 Meses</option>
                <option value='custom'>Personalizar</option>
            </select>
          </>
        )}

        {customPeriod && (
          <>
            <div className="row">
                <div className="col-2 fw-500">De</div>
                <div className="col">
                    <input type="date" name="period_start" ref={startRef} onChange={defineStart} className="form-control" />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-2 fw-500">Até</div>
                <div className="col">
                    <input type="date" name="period_end" ref={endRef} onChange={defineEnd} min='' max='today' className="form-control" />
                </div>
            </div>
            <div className="text-end text-primary fsc-n8 mt-1 cursor-pointer" onClick={backPredefined}>Voltar</div>
          </>
        )}
      </div>
    </>
  );
};

export default PeriodFilter;
