import FontAwesome from "../../../components/Layout/FontAwesome";

const Links = (props) => {
    const url = 'https://www.levolpi.com.br/cardapios/';
    const image = 'https://www.levolpi.com.br/cardapios/qrcode.png';

  return (
    <>
      <div className="box-widget">
        <h3 className="mb-4 fw-bold text-success">Qr Code</h3>

        <div className="row mb-4">
          <div className="col-lg-2 col-12 fw-bold">Link:</div>
          <div className="col">
            <input
              className="form-control"
              readOnly
              disabled
              defaultValue={url}
            />
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-2 col-12 fw-bold">Qr Code:</div>
          <div className="col-lg-4 col">
            <img
              style={{ width: 200 }}
              alt={image}
              src={image}
            />
            <div className="mt-4">
                <a href={image} download>
                    <button className="btn btn-primary"><FontAwesome icon='download' /> Baixar Qr Code</button>
                </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Links;
