const Entrega = ({ pedido }) => {
  return (
    <>
      <div className="mb-4">
        <h4 className="opacity-5 text-success fsc-1p2">Entrega</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <>
            <div className="fsc-1p2 mb-4 fw-light fsc-m-1">
              {pedido.address === null && (
                <>Cliente escolheu retirar pedido no restaurante.</>
              )}

              {pedido.address !== null && (
                <>
                  <div>
                    {pedido.address.logradouro}, {pedido.address.numero} -{" "}
                    {pedido.address.bairro} - {pedido.address.localidade}/
                    {pedido.address.uf}
                  </div>
                  {pedido.address.complemento !== "" && (
                    <div className="fsc-n9">{pedido.address.complemento}</div>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Entrega;
