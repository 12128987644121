import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const PaymentInfo = ({ pedido }) => {
  const paymentRef = useRef();
  const [payment, setPayment] = useState("LOCAL");

  const paymentMethodRef = useRef();

  useEffect(() => {
    setPayment(pedido.payment);
  }, [pedido]);

  function changePayment() {
    const value = paymentRef.current.value;

    setPayment(value);
  }

  return (
    <>
      <div className="payment">
        <h5 className="text-uppercase mb-3 text-secondary">Pagamento</h5>

        <div className="row">
          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Tipo de Pagamento</label>
              <select
                className="form-select"
                name="payment"
                defaultValue={pedido.payment}
                onChange={changePayment}
                ref={paymentRef}
              >
                <option value="LOCAL">Na entrega</option>
                <option value="DIRECT">Pelo site</option>
              </select>
            </div>
          </div>

          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Método</label>

              {payment === "DIRECT" && (
                <>
                  <select
                    className="form-select"
                    name="payment_method"
                    defaultValue={pedido.payment_method}
                    ref={paymentMethodRef}
                  >
                    <option value="pix">Pix</option>
                    <option value="credit">Cartão de Crédito</option>
                  </select>
                </>
              )}

              {payment === "LOCAL" && (
                <>
                  <select
                    className="form-select"
                    name="payment_method"
                    defaultValue={pedido.payment_method}
                    ref={paymentMethodRef}
                  >
                    {localPaymentMethods.map((paymentOption, index) => {
                      return (
                        <option key={index} value={paymentOption.value}>
                          {paymentOption.name}
                        </option>
                      );
                    })}
                  </select>
                </>
              )}
            </div>
          </div>
        </div>

        {pedido.payment_informations &&
          pedido.payment_informations.transaction_id && (
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="form-group">
                  <label>Código da Transação</label>
                  <input
                    className="form-control"
                    disabled
                    defaultValue={pedido.payment_informations.transaction_id}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export default PaymentInfo;

const localPaymentMethods = [
  {
    name: "Dinheiro",
    image: "/img/pagamento/dinheiro.png",
    value: "dinheiro",
  },
  {
    name: "Mastercard - Débito",
    image: "/img/pagamento/mastercard.png",
    value: "master_debito",
  },
  {
    name: "Visa - Débito",
    image: "/img/pagamento/visa.png",
    value: "visa_debito",
  },
  {
    name: "Elo - Débito",
    image: "/img/pagamento/elo.png",
    value: "elo_debito",
  },
  {
    name: "Visa - Crédito",
    image: "/img/pagamento/visa.png",
    value: "visa_credito",
  },
  {
    name: "Mastercard - Crédito",
    image: "/img/pagamento/mastercard.png",
    value: "mastercard_credito",
  },
  {
    name: "Elo - Crédito",
    image: "/img/pagamento/elo.png",
    value: "elo_credito",
  },
  {
    name: "Amex - Crédito",
    image: "/img/pagamento/amex.png",
    value: "amex_credito",
  },
  {
    name: "Ticket - Vale-Refeição",
    image: "/img/pagamento/ticket.png",
    value: "ticket",
  },
];
