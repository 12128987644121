import { Navigate, Route, Routes } from "react-router-dom";
import Entrar from "../../Paginas/Entrar/Entrar";

const OutletFull = () => {
  return (
    <>
      <Routes>
        <Route
          path="/entrar"
          element={<Entrar />}
        />
        
        <Route path="*" element={<Navigate to="/entrar" replace={true} />} />
      </Routes>
    </>
  );
};

export default OutletFull;
