import { useRef, useState } from "react";
import FileUpload from "./FileUpload";
import URLUpload from "./URLUpload";

const MenuUpload = (props) => {
  const [type, setType] = useState("file");
  const inputType = useRef();

  const changeType = () => {
    const value = inputType.current.value;

    setType(value);
  }

  return (
    <>
      <div className="box-widget">
        <h3 className="mb-4 fw-bold text-success">Alterar Arquivo</h3>

        <div className="row mb-4">
          <div className="col-lg-2 col-12 fw-bold">Tipo:</div>
          <div className="col">
            <select className="form-select w-auto" onChange={changeType} ref={inputType}>
              <option value="file">Enviar arquivo</option>
              <option value="url">Usar URL externa</option>
            </select>
          </div>
        </div>

        {type === "file" && (
          <>
            <FileUpload />
          </>
        )}
        

        {type === "url" && (
          <>
            <URLUpload />
          </>
        )}
      </div>
    </>
  );
};

export default MenuUpload;
