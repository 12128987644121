import { useEffect } from "react";
import { useRef } from "react";

const DocumentInput = ({ name, className, defaultValue, inputProps }) => {
  const realValueRef = useRef();
  const formatedValueRef = useRef();

  useEffect(() => {
    realValueRef.current.value = defaultValue;
    formatDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  function setRealValue() {
    const value = formatedValueRef.current.value;
    const realValue = value
      .replace(/\./g, "")
      .replace(/\//g, "")
      .replace(/-/g, "");

    realValueRef.current.value = realValue;
  }

  function formatDocument() {
    const realValue = realValueRef.current.value;
    let newValue = realValue;

    if (realValue.length > 2 && realValue.length < 12) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i === 3 || i === 6) {
          newValue += ".";
        }
        if (i === 9) {
          newValue += "-";
        }
        newValue += realValue.charAt(i);
      }
    }

    if (realValue.length > 11) {
      newValue = "";

      for (let i = 0; i < realValue.length; i++) {
        if (i > 0 && (i === 2 || i === 5)) {
          newValue += ".";
        }
        if (i > 0 && i === 8) {
          newValue += "/";
        }
        if (i > 0 && i === 12) {
          newValue += "-";
        }

        newValue += realValue.charAt(i);
      }
    }

    formatedValueRef.current.value = newValue;
  }

  function handleTyping() {
    setRealValue();
    formatDocument();
  }

  return (
    <>
      <input ref={realValueRef} className="hidden" name={name} />
      <input
        ref={formatedValueRef}
        className={className}
        onChange={handleTyping}
        {...inputProps}
        inputMode="number"
        pattern="[0-9\.-]{14}|[0-9\.-\/]{18}"
        maxLength={18}
      />
    </>
  );
};

export default DocumentInput;
