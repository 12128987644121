import { useContext } from "react";
import ListService from "../../../components/shared/Lists/ListService";
import { useNavigate } from "react-router-dom";
import FontAwesome from "../../../components/Layout/FontAwesome";

const ClientesLista = (props) => {
    const list = useContext(ListService);
    const navigate = useNavigate();
  
    function goToCustomer(id) {
      const id_ = id.toString();
      navigate("/clientes/" + id_);
    }

   return (
       <>
       <div className="m-n-content-mobile">
         <div className="box-widget-table">
           <div className="table-responsive">
             <table className="table table-style table-hover">
               <tbody className="table-group-divider align-middle bg-white border-top">
                 {list.list.length > 0 &&
                   list.list.map((customer, index) => {
                     return (
                       <tr
                         key={index}
                         className="cursor-pointer"
                         onClick={() => { goToCustomer(customer.id) }}
                       >
                         <td className="py-lg-4 py-3 px-4">
                            <div className="fw-500 mb-1 mb-lg-0">{customer.name}</div>
                            <div className="fsc-n7 d-lg-none opacity-6">
                                <div>{customer.tel_formated}</div>
                                <div>{customer.email}</div>
                            </div>
                         </td>
                         <td className="d-none d-lg-table-cell">
                           {customer.tel_formated}
                         </td>
                         
                         <td className="d-none d-lg-table-cell">
                           {customer.email}
                         </td>

                         <td
                           className="cell-min pe-lg-4 pe-3 fsc-1p2"
                           style={{ color: "var(--bs-gray-300)" }}
                         >
                           <FontAwesome type="solid" icon="chevron-right" />
                         </td>
                       </tr>
                     );
                   })}
               </tbody>
             </table>
           </div>
         </div>
       </div>
           
       </>
   )
}

export default ClientesLista;