import { useContext } from "react";
import AuthService from "../../../services/Auth/Auth";
import CSS from "./LoadingShield.module.css";

const LoadingShield = () => {
  const authService = useContext(AuthService);

  return (
    <div className={CSS.loading_shield + " align-items-center "+(!authService.firstCall && CSS.hide)}>
      <div className="w-100 text-center">
        <div className={CSS.loading_icon}>
          <img alt="" src="/img/logo/logo50.png" />
        </div>
        <div className={'mt-4 '+CSS.loading_text}>
            Carregando
        </div>
      </div>
    </div>
  );
};

export default LoadingShield;
