import { useContext } from "react";
import FontAwesome from "../../Layout/FontAwesome";
import ListService from "./ListService";

const UpAndDownList = (props) => {
  const listService = useContext(ListService);

  function goUp(e) {
    e.stopPropagation();

    const index2 = props.index - 1;

    let newList = swapIndexes(props.index, index2);

    if (!newList) {
      return;
    }

    listService.change(newList);
  }

  function goDown(e) {
    e.stopPropagation();

    const index2 = props.index + 1;

    let newList = swapIndexes(props.index, index2);

    if (!newList) {
      return;
    }

    listService.change(newList);
  }

  function swapIndexes(index1, index2) {
    let newList = listService.copy();

    if (props.nested) {
      const groupNested = getNested(newList);

      let temp = groupNested[index1];
      let temp2 = groupNested[index2];

      if (!temp2) {
        return false;
      }

      groupNested[index1] = temp2;
      groupNested[index2] = temp;
    } else {
      let temp = newList[index1];
      let temp2 = newList[index2];

      if (!temp2) {
        return false;
      }

      newList[index1] = temp2;
      newList[index2] = temp;
    }

    return newList;
  }

  function getNested(list) {
    let value = list;

    props.nested.forEach((key) => {
      value = value[key];
    });

    return value;
  }

  function getListLength() {
    const list = props.nested
      ? getNested(listService.list)
      : listService.list;

      return list.length;
  }

  return (
    <>
      {props.index > 0 && (
        <div className="btn btn-light mb-1 fsc-n8" onClick={goUp}>
          <FontAwesome icon="chevron-up" type="solid" />
        </div>
      )}
      <br />
      {props.index < getListLength(listService.list) - 1 && (
        <div className="btn btn-light mb-1 fsc-n8" onClick={goDown}>
          <FontAwesome icon="chevron-down" type="solid" />
        </div>
      )}
    </>
  );
};

export default UpAndDownList;
