import Price from "../../../../misc/classes/Filters/Price";

const AnalyticsCard = ({analytics}) => {
    const total = Price.numberToCurrency(analytics.sum);

   return (
       <>
            <div className="row my-4">
                <div className="col-6">
                    <div className="box-widget-table p-3">
                        <div className="fw-bold fsc-m-n8">Número de pedidos</div>
                        <div className="text-end fsc-1p5 fsc-m-1">
                            {analytics.number}
                        </div>
                    </div>
                </div>

                <div className="col-6">
                    <div className="box-widget-table p-3">
                        <div className="fw-bold fsc-m-n8">Soma Total</div>
                        <div className="text-end fsc-1p5 fsc-m-1">
                            {total}
                        </div>
                    </div>
                </div>
            </div>
       </>
   )
}

export default AnalyticsCard;