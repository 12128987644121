import { NavLink } from "react-router-dom";
import FontAwesome from "../FontAwesome";
import CSS from "./NavItem.module.css";

const NavItem = (props) => {
  return (
    <NavLink
      to={props.to}
      end={props.exact}
      className={({ isActive }) =>
        [CSS.nav_item, isActive ? CSS.nav_item_active : null]
          .filter(Boolean)
          .join(" ")
      }
    >
      <div className="text-white px-3 py-3">
        <div className="row mx-0">
          <div className="col-2">
            <FontAwesome
              type={props.fa.type}
              icon={props.fa.icon}
            ></FontAwesome>
          </div>
          <div
            className="col-10 text-uppercase fw-bold nav_hide_none"
            style={{ letterSpacing: "1px" }}
          >
            {props.children}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default NavItem;
