import { useContext, useRef, useState } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import Form from "../../../../components/shared/Facilitators/Form";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import CategoriasSelect from "../../Categorias/CategoriasSelect";
import ListService from "../../../../components/shared/Lists/ListService";
import { NumericFormat } from "react-number-format";
import CSS from "../../Produtos/ProdutosNovo.module.css";
import ProdutoFoto from "../ProdutoFoto";
import ButtonLoading from "../../../../components/Layout/ButtonLoading";
import Modal from "../../../../components/Layout/Modal/Modal";
import { useNavigate } from "react-router-dom";
import ImageAutoUpload from "../../../../components/shared/Facilitators/ImageAutoUpload";
import Opcoes from "../Opcoes/Opcoes";
import Promocao from "../Promocao/Promocao";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const ProdutoEditInner = ({ produto, onSave }) => {
  const listService = useContext(ListService);
  const navigate = useNavigate();
  const btnDel = useRef();
  const btnSave = useRef();
  const delModal = useRef();
  const promocaoRef = useRef();
  const priceRef = useRef();
  const [price, setPrice] = useState(produto.price);
  const [inPromocao, setInPromocao] = useState(false);

  useEffect(() => {
    if (verifyIfHasDiscount()) {
      setInPromocao(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (promocaoRef.current) {
      promocaoRef.current.toggle(inPromocao);
    }
  }, [inPromocao]);

  function onSubmit(formData) {
    btnSave.current.setLoading(true);
    formData.append("ingredients", listService.json());
    HttpRequest.post("/produtos/" + produto.id, formData, onSuccess);
  }

  function onSuccess() {
    btnSave.current.setLoading(false);
    listService.reset();
    onSave();
  }

  function deleteConfirm() {
    btnDel.current.setLoading(true);

    const formData = new FormData();
    formData.append("_method", "DELETE");

    HttpRequest.post("/produtos/" + produto.id, formData, onSuccessDeleted);
  }

  function onSuccessDeleted(data) {
    btnDel.current.setLoading(false);
    delModal.current.close();
    navigate("/produtos");
  }

  function togglePromocao() {
    setInPromocao(!inPromocao);
  }

  function priceChanged() {
    const newPrice = priceRef.current.value;
    setPrice(newPrice);
  }

  function verifyIfHasDiscount() {
    return (
      produto.discount &&
      produto.discount !== "0" &&
      produto.discount !== produto.price
    );
  }

  return (
    <>
      <NavLink
        to={"/categorias/" + produto.category + "/produtos"}
        style={{ textDecoration: "unset" }}
      >
        <div className="mb-4 text-success opacity-7 text-center text-lg-start">
          <span className="me-2">
            <FontAwesome type="solid" icon="chevron-left" />
          </span>
          Voltar para a categoria
        </div>
      </NavLink>

      <div className="row align-items-center">
        <div className="col-lg-auto col-12 text-center mb-4 mb-lg-0">
          <ProdutoFoto {...produto} />
        </div>
        <div className="col-lg col-12">
          <h1 className="mb-0">{produto.name}</h1>
        </div>
      </div>

      <div className="mt-lg-5 mb-4 "></div>

      <Form
        className="box-widget m-n-content-mobile"
        method="PUT"
        onSubmit={onSubmit}
      >
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Titulo</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                defaultValue={produto.name}
                required
              />
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="category">Categoria</label>
              <CategoriasSelect defaultValue={produto.category} />
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="price">Preço</label>
              <div className="row align-items-center">
                <div className="col-auto pe-0">R$</div>
                <div className="col">
                  <NumericFormat
                    id={"price"}
                    name={"price"}
                    className="form-control"
                    displayType="input"
                    valueIsNumericString={true}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    fixedDecimalScale={true}
                    placeholder="0,00"
                    defaultValue={produto.price_formated}
                    inputMode="decimal"
                    onChange={priceChanged}
                    getInputRef={priceRef}
                  />
                </div>
                <div className="col-auto">
                  <div
                    className={
                      "btn " +
                      (inPromocao ? "btn-warning" : "btn-outline-warning")
                    }
                    onClick={togglePromocao}
                  >
                    {inPromocao && (
                      <FontAwesome icon="square-check" type="regular" />
                    )}
                    {!inPromocao && (
                      <FontAwesome icon="square" type="regular" />
                    )}

                    <span className="ms-2 fw-bold">Promoção</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {inPromocao && (
          <Promocao
            defaultPrice={price}
            defaultDiscount={produto.discount}
            ref={promocaoRef}
          />
        )}
        {!inPromocao && (
          <input
            name="discount"
            type="text"
            className="hidden"
            defaultValue="0"
          />
        )}

        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="visible">Visível</label>
              <select
                className="form-select"
                id="visible"
                name="visible"
                defaultValue={produto.visible}
              >
                <option value="1">Sim</option>
                <option value="0">Não</option>
              </select>
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="visible">Destaque</label>
              <select
                className="form-select"
                id="visible"
                name="destaque"
                defaultValue={produto.destaque}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="category">Serve até</label>
              <div className="row align-items-center">
                <div className="col">
                  <input
                    name="people"
                    type="number"
                    defaultValue={produto.people}
                    className="form-control"
                  />
                </div>
                <div className="col-auto">pessoas</div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="description">Descrição</label>
          <textarea
            type="text"
            name="description"
            id="description"
            className="form-control"
            defaultValue={produto.description}
          ></textarea>
        </div>

        <div className="form-group">
          <div className="fw-bold">Foto</div>

          <div className={CSS.photos + " mt-2"}>
            <div className="row">
              <div className="col-lg-auto col-12">
                <ImageAutoUpload name="photo" defaultValue={produto.photo}>
                  <div className={CSS.photos_placeholder}>
                    <FontAwesome icon="camera" type="solid" />
                  </div>
                </ImageAutoUpload>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Opções</label>

          <Opcoes edit={true} />
        </div>

        <div className="row mt-5 justify-content-between">
          <div className="col-lg-auto col-12">
            <ButtonLoading className="text-primary" ref={btnSave}>
              <button className="btn btn-primary fw-bold w-100 px-4">
                Salvar
              </button>
            </ButtonLoading>
          </div>
          <div className="col-lg-auto col-12 mt-2 mt-lg-0">
            <div
              className="btn btn-outline-danger fw-bold w-100"
              data-bs-toggle="modal"
              data-bs-target="#deletar-produto"
            >
              Deletar
            </div>
          </div>
        </div>
      </Form>

      <Modal id="deletar-produto" title="Deletar Produto" ref={delModal}>
        <div>Deseja realmente deletar este produto?</div>
        <div>Não é possível desfazer esta ação.</div>

        <div className="row justify-content-between mt-5">
          <div className="col-auto">
            <div className="btn btn-secondary" data-bs-dismiss="modal">
              Cancelar
            </div>
          </div>
          <div className="col-auto">
            <ButtonLoading className="text-danger" ref={btnDel}>
              <div className="btn btn-danger" onClick={deleteConfirm}>
                Deletar
              </div>
            </ButtonLoading>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProdutoEditInner;
