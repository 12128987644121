import { useState } from "react";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import FontAwesome from "../FontAwesome";

const Alert = forwardRef((props, ref) => {
    const [isOpen, setVisibility] = useState(false);

    useImperativeHandle(ref, () => ({
        open, close
    }));

    function open(){
        setVisibility(true);
    }

    function close(){
        setVisibility(false);
    }

  return (
    <div
      className={
        "alert " +
        " " +
        props.className + 
        " " +
        ((!props.dismissible || isOpen) && 'd-block') +
        " " +
        ((props.dismissible && !isOpen) && 'd-none')
      }
    >
        <div className="row">
            <div className={'col-auto pe-0 ' + (!props.icon && 'd-none')}>
                {
                    props.icon && (
                        <FontAwesome type='solid' icon={props.icon} />
                    )
                }
            </div>
            <div className="col">
                {props.children}
            </div>
            <div className={"col-auto cursor-pointer " + (!props.dismissible && 'd-none')} onClick={close}>
                <FontAwesome type='solid' icon='xmark' />
            </div>
        </div>
    </div>
  );
});

export default Alert;
