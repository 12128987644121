import CSS from "./Tags.module.css";

const StatusTags = ({ status, address }) => {
  return (
    <>
      <div className={'rounded px-2 py-1 fsc-n9 d-inline-block opacity-7 fw-500 ' + CSS[status]}>
        {status === "COMPLETED" && "Concluído"}
        {status === "DELIVERING" && "Rota de Entrega"}
        {status === "WAITING_DELIVER" && address !== null &&  "Aguardando Entregador"}
        {status === "WAITING_DELIVER" && address === null &&  "Aguardando Retirada"}
        {status === "PREPARING" && "Preparando"}
        {status === "ANALYZING" && "Requer Análise"}
        {status === "PAID" && "Pago"}
        {status === "PROCESSING" && "Aguardando Pagamento"}
        {status === "CANCELED" && "Cancelado"}
      </div>
    </>
  );
};

export default StatusTags;
