import { useContext } from "react";
import UpAndDownList from "../../../components/shared/Lists/UpAndDownList";
import ListService from "../../../components/shared/Lists/ListService";
import FontAwesome from "../../../components/Layout/FontAwesome";
import { useNavigate } from "react-router-dom";
import TagNotVisible from "../../../components/Layout/Tags/TagNotVisible";

const CategoriaLista = () => {
  const listService = useContext(ListService);
  const navigate = useNavigate();

  function goTo(id) {
    navigate("/categorias/" + id);
  }

  return (
    <>
      <div className="m-n-content-mobile">
        <div className="box-widget-table">
          <div className="table-responsive">
            <table className="table table-style table-hover">
              <tbody className="table-group-divider align-middle bg-white border-top">
                <tr
                  className="cursor-pointer"
                  onClick={goTo.bind(null, "destaques")}
                >
                    <td></td>
                  <td className="fw-bold text-uppercase fsc-1p2 fsc-m-1 py-4">
                    Destaques
                  </td>
                  <td
                    className="cell-min px-4 fsc-1p2"
                    style={{ color: "var(--bs-gray-300)" }}
                  >
                    <FontAwesome type="solid" icon="chevron-right" />
                  </td>
                </tr>
              <tr
                  className="cursor-pointer"
                  onClick={goTo.bind(null, "promocoes")}
                >
                    <td></td>
                  <td className="fw-bold text-uppercase fsc-1p2 fsc-m-1 py-4">
                    Promoções
                  </td>
                  <td
                    className="cell-min px-4 fsc-1p2"
                    style={{ color: "var(--bs-gray-300)" }}
                  >
                    <FontAwesome type="solid" icon="chevron-right" />
                  </td>
                </tr>
                {listService.list.length > 0 &&
                  listService.list.map((categoria, index) => {
                    return (
                      <tr
                        key={index}
                        className="cursor-pointer"
                        onClick={goTo.bind(null, categoria.id)}
                      >
                        <td className="cell-min px-3">
                          <UpAndDownList index={index} />
                        </td>
                        <td className="fw-bold text-uppercase fsc-1p2 fsc-m-1 py-4">
                          {categoria.name}
                          {categoria.visible === 0 && <TagNotVisible />}
                        </td>
                        <td
                          className="cell-min px-4 fsc-1p2"
                          style={{ color: "var(--bs-gray-300)" }}
                        >
                          <FontAwesome type="solid" icon="chevron-right" />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriaLista;
