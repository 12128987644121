import FontAwesome from "../../../components/Layout/FontAwesome";

const CuponsItem = ({ cupom, onEdit }) => {
  function editCupom() {
    onEdit(cupom);
  }

  return (
    <>
      <tr className="cursor-pointer" onClick={editCupom}>
        <td className="cell-min px-4">{cupom.id}</td>

        <td className="px-4 fw-500 py-4">{cupom.name}</td>

        <td className="cell-min px-4 fw-bold text-primary">
          {cupom.type === 'fixed' && (<>
            R$ {cupom.discount_formated}
          </>)}
          {cupom.type === "percentage" && (
            <>
              {cupom.discount}
              <span className="ms-1 fsc-n7">
                <FontAwesome icon="percentage" />
              </span>
            </>
          )}
          {cupom.type === "delivery" && "Frete Grátis"}
        </td>

        <td className="cell-min px-4">{cupom.quantity}</td>

        <td className="cell-min px-4">{cupom.expire_formated}</td>
      </tr>
    </>
  );
};

export default CuponsItem;
