import { forwardRef, useImperativeHandle, useState } from "react";

const ButtonLoading = forwardRef(({className, children}, ref) => {
  const [loading, setLoadingState] = useState(false);

  useImperativeHandle(ref, () => ({
    setLoading,
  }));

  function setLoading(state) {
    setLoadingState(state);
  }

  return (
    <>
      {loading && (
        <div className={"d-inline-block px-4 fsc-1p2 " + className}>
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </div>
        </div>
      )}

      {!loading && children}
    </>
  );
});

export default ButtonLoading;
