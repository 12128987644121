import { useState } from "react";
import { ListServiceProvider } from "../../../../components/shared/Lists/ListService";
import { useEffect } from "react";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import PedidosLista from "./PedidosLista";
import Filters from "./Filters/Filters";
import Pagination from "../../../../components/Layout/Pagination/Pagination";
import { useRef } from "react";
import AnalyticsCard from "./AnalyticsCard";

const FullList = ({user = 0}) => {
  const [firstCall, setFirstCall] = useState(true);
  const [list, setList] = useState([]);
  const [paginas, setPaginas] = useState(null);
  const [loadingList, setLoadingList] = useState(true);
  const [analytics, setAnalytics] = useState({number: 0, sum: 0});
  
  const filtersRef = useRef();
  const paginationRef = useRef();

  useEffect(() => {
    getPedidos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPedidos(resetPagination = false) {
    setLoadingList(true);

    const pageParam = !resetPagination ? paginationRef.current.fetchParam() : null;
    let filtersParams = filtersRef.current.fetchParams();

    if(user > 0){
        filtersParams = filtersParams ? filtersParams + '&user='+user : 'user='+user;
    };

    let params = [];

    if (filtersParams || pageParam) {
      if (filtersParams) params.push(filtersParams);
      if (pageParam) params.push(pageParam);

      params = "?" + params.join("&");
    }

    if (firstCall) setFirstCall(false);
    HttpRequest.get("/pedidos" + params, onSuccess);
  }

  function onSuccess(data) {
    setLoadingList(false);
    setList(data.list);
    if (data.pages) setPaginas(data.pages);
    if (data.analytics) setAnalytics(data.analytics);
  }

  return (
    <>
      <ListServiceProvider
        list={list}
      >
        <Filters ref={filtersRef} onFilter={getPedidos} firstCall={firstCall} />

        <div className="mb-2"></div>

        {loadingList && (
          <div className="text-center my-5 text-secondary">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        )}

        <div className={`${loadingList && "d-none"}`}>
          <AnalyticsCard analytics={analytics} />

          <Pagination
            ref={paginationRef}
            paginas={paginas}
            onChange={getPedidos}
          >
            <PedidosLista />
          </Pagination>
        </div>
      </ListServiceProvider>
    </>
  );
};

export default FullList;
