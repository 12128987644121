import Price from "../../../../../misc/classes/Filters/Price";

const Valores = ({ pedido }) => {
  return (
    <>
      <div className="row fw-light mb-3 opacity-7">
        <div className="col">Subtotal</div>
        <div className="col-auto">
          {pedido.id === null && "R$ 0,00"}
          {pedido.id !== null &&
            Price.numberToCurrency(
              pedido.total - pedido.delivery_fee + pedido.discount
            )}
        </div>
      </div>

      <div className="row fw-light opacity-7 mb-3">
        <div className="col">Taxa de Entrega</div>
        <div className="col-auto">
          {pedido.id === null && "R$ 0,00"}
          {pedido.id !== null && Price.numberToCurrency(pedido.delivery_fee)}
        </div>
      </div>

      {pedido.discount > 0 && (
        <>
          <div className="row fw-light opacity-7 mb-3">
            <div className="col">Desconto</div>
            <div className="col-auto">
              {pedido.id === null && "R$ 0,00"}
              {pedido.id !== null && Price.numberToCurrency(pedido.discount)}
            </div>
          </div>
        </>
      )}

      <div className="row fw-500 fsc-1p2 fsc-m-1">
        <div className="col">Total</div>
        <div className="col-auto">
          {pedido.id === null && "R$ 0,00"}
          {pedido.id !== null && Price.numberToCurrency(pedido.total)}
        </div>
      </div>
    </>
  );
};

export default Valores;
