import Acesso from "../Paginas/Painel/Acesso/Acesso";
import Categorias from "../Paginas/Painel/Categorias/Categorias";
import Categoria from "../Paginas/Painel/Categorias/Categoria/Categoria";
import Produtos from "../Paginas/Painel/Produtos/Produtos";
import ProdutoNovo from "../Paginas/Painel/Produtos/ProdutoNovo/ProdutoNovo";
import ProdutoEdit from "../Paginas/Painel/Produtos/ProdutoEdit/ProdutoEdit";
import DestaquePromocoes from "../Paginas/Painel/Categorias/DestaquePromocoes";
import Clientes from "../Paginas/Painel/Clientes/Clientes";
import Cliente from "../Paginas/Painel/Clientes/Cliente/Cliente";
import Pedidos from "../Paginas/Painel/Pedidos/Pedidos";
import { Navigate } from "react-router-dom";
import Cupons from "../Paginas/Painel/Cupons/Cupons";
import Configuracoes from "../Paginas/Painel/Configuracoes/Configuracoes";
import QrCode from "../Paginas/Painel/QrCode/QrCode";

const routesList = [
  { path: "/", component: <Navigate to="/pedidos" />, index: true },
  {
    path: "/pedidos/*",
    component: <Pedidos />,
  },
  {
    path: "/categorias",
    component: <Categorias />,
  },
  {
    path: "/categorias/promocoes",
    component: <DestaquePromocoes uri="promocoes" title="Promoções" />,
  },
  {
    path: "/categorias/destaques",
    component: <DestaquePromocoes uri="destaques" title="Destaques" />,
  },
  {
    path: "/categorias/:categoryId/*",
    component: <Categoria />,
  },
  {
    path: "/produtos",
    component: <Produtos />,
  },
  {
    path: "/produtos/novo",
    component: <ProdutoNovo />,
  },
  {
    path: "/produtos/:productId",
    component: <ProdutoEdit />,
  },
  {
    path: "/clientes",
    component: <Clientes />,
  },
  {
    path: "/clientes/:customerId/*",
    component: <Cliente />,
  },
  {
    path: "/cupons",
    component: <Cupons />
  },
  {
    path: "/configuracoes",
    component: <Configuracoes />
  },
  {
    path: '/qrcode',
    component: <QrCode />
  },
  {
    path: "/acesso",
    component: <Acesso />,
  },
];

export const routesListVisitor = [
    { path: "/", component: <Navigate to="/qrcode" />, index: true },
    {
      path: '/qrcode',
      component: <QrCode />
    }
];

export default routesList;
