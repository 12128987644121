import CSS from "./Tags.module.css";

const DeliveryTags = ({ address }) => {
  return (
    <>
      <div
        className={
          "rounded px-2 py-1 fsc-n9 d-inline-block opacity-7 fw-500 " +
          CSS[address === null ? 'presencial' : 'entregar']
        }
      >
        {address === null && <>Retirar</>}
        {address !== null && <>Entregar</>}
      </div>
    </>
  );
};

export default DeliveryTags;
