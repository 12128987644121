import { useRef } from 'react';
import { useEffect } from 'react';
import { NavLink, useMatch } from 'react-router-dom';

function NavHorizontalItem(props) {
  const match = useMatch('/categorias/:categoryId/'+props.to);
  const linkRef = useRef();
  
  function scrollTo(item) {
    linkRef.current.scrollIntoView();
  }

  useEffect(() => {
    if (match) {
        scrollTo();
    }
  }, [match]);

  return (
    <NavLink {...props} ref={linkRef}>
      {props.children}
    </NavLink>
  );
}

export default NavHorizontalItem;