import { useContext } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import UpAndDownList from "../../../../components/shared/Lists/UpAndDownList";
import CSS from "./IngredientesPhoto.module.css";
import ModalOptionsService from "./Modals/ModalsProvider";

const Ingrediente = ({ ingredient, group, index }) => {
    const modalOptionsService = useContext(ModalOptionsService);

    function openEdit(){
        modalOptionsService.editOption(group, index)
    }

    function deleteIngredient(){
        modalOptionsService.deleteOption(group, index);
    }

  return (
    <tr>
      <td className="cell-min px-lg-3 ps-lg-3">
        {group !== null && (
          <UpAndDownList index={index} nested={[group, "ingredients"]} />
        )}
      </td>
      <td className="cell-min text-center d-none d-lg-table-cell">
        {ingredient.photo && (
          <div className={CSS.photo_min}>
            {ingredient.photo.indexOf("/temp/") === -1 ? (
              <img
                src={
                  process.env.REACT_APP_IMG_URL +
                  "/ingredientes/" +
                  ingredient.photo
                }
                alt=""
              />
            ) : (
              <img src={process.env.REACT_APP_URL + ingredient.photo} alt="" />
            )}
          </div>
        )}
        {!ingredient.photo && (
          <div className="opacity-6">
            <FontAwesome type="solid" icon="camera" />
          </div>
        )}
      </td>
      <td>
        <div>{ingredient.name}</div>
      </td>
      <td>
        <div>R$ {ingredient.price ? ingredient.price : "-"}</div>
      </td>
      <td className="cell-min px-2">
        <div
          className="btn btn-light text-danger fsc-1"
          onClick={deleteIngredient}
        >
          <FontAwesome type="solid" icon="trash-can" />
        </div>
        <div
          className="btn btn-light text-primary ms-2 fsc-1"
          onClick={openEdit}
        >
          <FontAwesome type="solid" icon="pen" />
        </div>
      </td>
    </tr>
  );
};

export default Ingrediente;
