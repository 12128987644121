
import CSS from "./NavHorizontal.module.css";
import NavHorizontalItem from "./NavHorizontalItem";

const NavHorizontal = ({ routes, classOuter }) => {

  return (
    <div className={CSS.nav_outer + " ps-1 " + classOuter}>
      <div className={CSS.nav_tab}>
        {routes.map((route, i) => (
        //   <div className={CSS.nav_item} key={i}>
            <NavHorizontalItem
            key={i}
              className={CSS.nav_link}
              to={route.to}
              end={route.end}
            >
              {route.name}
            </NavHorizontalItem>
        //   </div>
        ))}
      </div>
    </div>
  );
};

export default NavHorizontal;
