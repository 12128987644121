import { useRef, useState } from "react";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import PassInput from "../../../components/Layout/Forms/PassInput";
import FontAwesome from "../../../components/Layout/FontAwesome";
import ButtonLoading from "../../../components/Layout/ButtonLoading";

const Visitor = (props) => {
  const formRef = useRef();
  const btnSaveRef = useRef();
  const [changedPass, setChangedPass] = useState(null);

  function onSubmit(e) {
    e.preventDefault();

    const formData = new FormData(formRef.current);

    btnSaveRef.current.setLoading(true);

    HttpRequest.put("/auth/visitor", formData, onSuccess, onError);
  }

  function onSuccess(data) {
    btnSaveRef.current.setLoading(false);
    setChangedPass(true);
  }

  function onError(error) {
    btnSaveRef.current.setLoading(false);
    setChangedPass(false);
  }

  return (
    <>
      <form className="box-widget" onSubmit={onSubmit} ref={formRef}>
        <h3 className="mb-4 fw-bold text-success">Visitante</h3>

        <div className="row">
          <div className="col-lg-2 col-12 fw-bold">Usuário:</div>
          <div className="col-lg-auto col">
            <input
              className="form-control"
              name="username"
              readOnly
              value={'visitante'}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-lg-2 col-12 fw-bold">Nova Senha:</div>
          <div className="col-lg-auto col">
            <PassInput name="new_password" required />
          </div>
        </div>

        {changedPass && (
          <>
            <div className="alert alert-success d-inline-block mt-4">
              <FontAwesome type="solid" icon="check" /> Senha alterada com
              sucesso!
            </div>
          </>
        )}

        <div className="text-lg-start text-end mt-lg-2 mt-4">
          <ButtonLoading ref={btnSaveRef}>
            <button className="btn btn-primary fw-bold">Alterar senha</button>
          </ButtonLoading>
        </div>
      </form>
    </>
  );
};

export default Visitor;
