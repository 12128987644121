import { useEffect } from "react";
import { ListServiceProvider } from "../../../components/shared/Lists/ListService";
import { useState } from "react";
import SearchList from "../../../components/shared/Lists/SearchList/SearchList";
import ProdutosLista from "../Produtos/ProdutosLista";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import SaveProductsPositions from "./SaveProductsPositions";

const DestaquePromocoes = ({ title, uri }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProdutos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getProdutos() {
    HttpRequest.get("/produtos/"+uri, onSuccess);
  }

  function onSuccess(data) {
    setProducts(data.list);
  }

  return (
    <>
    <h1>{title}</h1>

    <div className="mt-5"></div>

      <ListServiceProvider
        list={products}
        searchIn={["name", "category_name", "description"]}
      >
        <SearchList />
        <div className="mb-2 mb-lg-4"></div>
        <ProdutosLista ordering={true} />
        
        <div className="d-block d-lg-inline-block my-5 text-center">
            <SaveProductsPositions categoryId={uri} />
        </div>
      </ListServiceProvider>
    </>
  );
};

export default DestaquePromocoes;
