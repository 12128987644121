import { forwardRef } from "react";
import Modal from "../../../../../components/Layout/Modal/Modal";
import { useImperativeHandle } from "react";
import { useRef } from "react";
import FontAwesome from "../../../../../components/Layout/FontAwesome";
import Items from "./Items";
import Valores from "./Valores";
import Status from "./Status";
import Data from "./Data";
import Entrega from "./Entrega";
import Pagamento from "./Pagamento";
import Cliente from "./Cliente";
import HttpRequest from "../../../../../misc/classes/HttpRequest/HttpRequest";
import { useState } from "react";
import ActionButton from "./ActionButton";
import PrintOrder from "./PrintOrder/PrintOrder";
import { useNavigate } from "react-router-dom";

const ModalPedido = forwardRef(({ updateList }, ref) => {
  const modalRef = useRef();
  const [pedido, setPedido] = useState({ id: null });
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({ open }));

  function open(id) {
    getPedido(id);
    modalRef.current.open();
  }

  function getPedido(id) {
    HttpRequest.get("/pedidos/" + id, onPedido, close);
  }

  function onPedido(response) {
    setPedido(response.order);
  }

  function close() {
    modalRef.current.close();
    setTimeout(() => {
      setPedido({ id: null });
    }, 500);
  }

  function updateOrder(response) {
    const status = response.order_status;

    const newPedido = { ...pedido };

    newPedido.status = status;

    setPedido(newPedido);

    updateList();
  }

  function goToOrder(){
    close();

    const id_ = pedido.id.toString();
    navigate("/pedidos/" + id_);
  }

  return (
    <>
      <Modal
        id={"modal-orders"}
        isStatic={true}
        ref={modalRef}
        className="modal-xl"
      >
        <div className="px-lg-5 px-2 pb-4" id="order-resume">
          <div className="row">
            <div className="col-lg-7 col-12 pe-lg-5 mb-4 mb-lg-0">
              <div className="row mb-5 align-items-center justify-content-between">
                <div className="col-auto">
                  <h2 className="text-success fsc-2">
                    <span className="opacity-7 me-2">
                      <FontAwesome icon="receipt" type="solid" />
                    </span>{" "}
                    Pedido {pedido.id}
                  </h2>
                </div>
                <div className="col-lg-auto col-12 my-3 my-lg-0">
                  <Status pedido={pedido} />
                </div>
                <div className="col-lg-auto col-12">
                  <Data pedido={pedido} />
                </div>
              </div>

              <Items pedido={pedido} />

              <hr className="my-lg-4 my-5 opacity-1" />

              <Valores pedido={pedido} />
            </div>

            <div className={"col ps-lg-5 "}>
              <Cliente pedido={pedido} />

              <Entrega pedido={pedido} />

              <Pagamento pedido={pedido} />

              {pedido.id !== null && (
                <div className="row">
                  <div className="col-12 mb-3">
                    <ActionButton pedido={pedido} updateOrder={updateOrder} />
                  </div>

                  <div className="col-12 mb-3">
                    {pedido.id !== null && <PrintOrder pedido={pedido} />}
                  </div>

                  <div className="col-12">
                    <div className="btn btn-light border text-uppercase fw-500 text-secondary w-100 py-2" onClick={goToOrder}>
                      <FontAwesome type="solid" icon="plus" /> Opções
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default ModalPedido;
