import { useEffect } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useRef } from "react";
import { useState } from "react";

const Pagination = forwardRef(({ paginas = null, children, onChange }, ref) => {
  const selectRef = useRef();
  const select2Ref = useRef();
  const [list, setList] = useState([]);

  useImperativeHandle(ref, ()=>({
    fetchParam
  }));

  useEffect(() => {
    if (paginas !== null) {
      generateList();

      selectRef.current.value = paginas.current;
      select2Ref.current.value = paginas.current;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginas]);

  function generateList() {
    const list = [];

    for (let index = 1; index <= paginas.total; index++) {
      list.push(index);
    }

    setList(list);
  }

  function fetchParam(){
    if(!selectRef.current || selectRef.current.value === 1){
        return null;
    };

    return 'page='+selectRef.current.value;
  }
  
  function changePage(){
    select2Ref.current.value = selectRef.current.value;
    onChangePage();    
  }

  function changePage2(){
    selectRef.current.value = select2Ref.current.value;
    onChangePage();
  }

  function onChangePage(){
    onChange();
  }

  return (
    <>
      {paginas === null && children}

      {paginas !== null && (
        <>
          <div className="row justify-content-end align-items-center">
            <div className="col-auto">
              Página:
            </div>
            <div className="col-auto">
              <select className="form-select w-auto" ref={selectRef} onChange={changePage}>
                {list.map((page, i) => {
                  return (
                    <option key={i} value={page}>
                      {page}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="my-4">{children}</div>

          <div className="row justify-content-end align-items-center">
            <div className="col-auto">
              Página:
            </div>
            <div className="col-auto">
              <select className="form-select w-auto" ref={select2Ref} onChange={changePage2}>
                {list.map((page, i) => {
                  return (
                    <option key={i} value={page}>
                      {page}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default Pagination;
