import { useEffect } from "react";
import { useState } from "react";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import { useRef } from "react";

const CategoriasSelect = ({ name, id, defaultValue, except, required }) => {
  const [listCategorias, setListCategorias] = useState([]);
  const selectRef = useRef();

  useEffect(() => {
    getCategorias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    changeDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  function getCategorias() {
    HttpRequest.get("/categorias", onSuccessCategorias);
  }

  function onSuccessCategorias(data) {
    setListCategorias(excludeExceptions(data.list));

    changeDefault();
  }

  function changeDefault(){
    if (defaultValue) {
      setTimeout(() => {
        selectRef.current.value = defaultValue;
      }, 10);
    }
  }

  function excludeExceptions(list){
    if(!except) return list;

    let newList = [];

    list.forEach(element => {
        if (!except.includes(element.id)) {
            newList.push(element);
        };
    });

    return newList;
  }

  return (
    <select
      className="form-select"
      name={!name ? "category" : name}
      id={!id ? "category" : id}
      defaultValue={0}
      ref={selectRef}
      required={required}
    >
      <option value=''>Selecione a categoria</option>
      {listCategorias.map((categoria, i) => {
        return (
          <option key={i} value={categoria.id}>
            {categoria.name}
          </option>
        );
      })}
    </select>
  );
};

export default CategoriasSelect;
