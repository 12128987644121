import { useEffect, useRef, useState } from "react";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import Form from "../../../../components/shared/Facilitators/Form";
import ButtonLoading from "../../../../components/Layout/ButtonLoading";

const Horarios = (props) => {
  const [daysOfWeek, setDaysOfWeek] = useState(null);
  const [today, setToday] = useState("0");
  const [closed, setClosed] = useState("0");

  const formRef = useRef();
  const btnSave = useRef();
  const todayRef = useRef();
  const todayMessageRef = useRef();
  const closedRef = useRef();
  const closedMessageRef = useRef();

  useEffect(() => {
    getHorarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getHorarios() {
    HttpRequest.get("/configuracoes/horarios", fillHorarios);
  }

  function fillHorarios(response) {
    if (response.horarios !== null) {
      const horarios = response.horarios;
      todayRef.current.value = horarios.today;
      todayMessageRef.current.value = horarios.today_message
        ? horarios.today_message
        : null;
      closedRef.current.value = horarios.closed;
      closedMessageRef.current.value = horarios.closed_message
        ? horarios.closed_message
        : null;

      setToday(horarios.today);
      setClosed(horarios.closed);
      setDaysOfWeek(horarios.days);
    } else {
      setDaysOfWeek(listDays);
    }
  }

  function onSubmit(form) {
    btnSave.current.setLoading(true);
    HttpRequest.post("/configuracoes/horarios", form, onSuccess, onError);
  }

  function onSuccess(response) {
    btnSave.current.setLoading(false);
  }

  function onError() {
    btnSave.current.setLoading(false);
  }

  function changeToday() {
    setToday(todayRef.current.value);
  }

  function changeClosed() {
    setClosed(closedRef.current.value);
  }

  return (
    <>
      <Form className="box-widget" onSubmit={onSubmit} ref={formRef}>
        <h3 className="mb-4 fw-bold text-success">Horários</h3>

        <div className="row mb-4">
          <div className="col-lg-2 col-12 fw-bold">Fechado:</div>
          <div className="col-lg-4 col">
            <select
              name="closed"
              className="form-select w-auto"
              ref={closedRef}
              onChange={changeClosed}
            >
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>

            <div
              className={
                "row mt-2 align-items-center " + (closed === "0" && "d-none")
              }
            >
              <div className="col-auto">Motivo:</div>
              <div className="col">
                <input
                  className="form-control"
                  name="closed_message"
                  ref={closedMessageRef}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-lg-2 col-12 fw-bold">Fechado Hoje:</div>
          <div className="col-lg-4 col">
            <select
              name="today"
              className="form-select w-auto"
              ref={todayRef}
              onChange={changeToday}
            >
              <option value="0">Não</option>
              <option value="1">Sim</option>
            </select>

            <div
              className={
                "row mt-2 align-items-center " + (today === "0" && "d-none")
              }
            >
              <div className="col-auto">Motivo:</div>
              <div className="col">
                <input
                  className="form-control"
                  name="today_message"
                  ref={todayMessageRef}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-lg-2 col-12 fw-bold">Dias e horários:</div>
          <div className="col-lg-10 col-12">
            {daysOfWeek !== null &&
              daysOfWeek.map((dia, index) => {
                return (
                  <div
                    className="row mb-3 mb-lg-1 align-items-center"
                    key={index}
                  >
                    <div className="col-lg-2 col-12 mb-1 mb-lg-0 fw-bold">
                      {dia.name}
                    </div>

                    <div className="col-lg-2 col-12 mb-1 mb-lg-0">
                      <select
                        name={dia.value + "_open"}
                        className="form-select"
                        defaultValue={dia.open}
                      >
                        <option value="0">Fechado</option>
                        <option value="1">Aberto</option>
                      </select>
                    </div>

                    <div className="col-lg-auto col">
                      <div className="row align-items-center">
                        <div className="col pe-1">
                          <input
                            type="number"
                            name={dia.value + "_hour_start"}
                            className="form-control"
                            min="0"
                            max="23"
                            defaultValue={dia.start.hour}
                            placeholder="HH"
                          />
                        </div>
                        <div className="col-auto px-0">:</div>
                        <div className="col ps-1">
                          <input
                            type="number"
                            name={dia.value + "_minute_start"}
                            className="form-control"
                            min="0"
                            max="59"
                            defaultValue={dia.start.minute}
                            placeholder="MM"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-auto col-auto fsc-n7 px-0">até</div>
                    <div className="col-lg-auto col">
                      <div className="row align-items-center">
                        <div className="col pe-1">
                          <input
                            type="number"
                            name={dia.value + "_hour_end"}
                            className="form-control"
                            min="0"
                            max="23"
                            defaultValue={dia.end.hour}
                            placeholder="HH"
                          />
                        </div>
                        <div className="col-auto px-0">:</div>
                        <div className="col ps-1">
                          <input
                            type="number"
                            name={dia.value + "_minute_end"}
                            className="form-control"
                            min="0"
                            max="59"
                            defaultValue={dia.end.minute}
                            placeholder="MM"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="text-lg-start text-end mt-4">
          <ButtonLoading className="text-primary" ref={btnSave}>
            <button className="btn btn-primary">Salvar</button>
          </ButtonLoading>
        </div>
      </Form>
    </>
  );
};

export default Horarios;

const listDays = [
  {
    name: "Domingo",
    value: "sunday",
    open: "0",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
  {
    name: "Segunda-feira",
    value: "monday",
    open: "1",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
  {
    name: "Terça-feira",
    value: "tuesday",
    open: "1",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
  {
    name: "Quarta-feira",
    value: "wednesday",
    open: "1",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
  {
    name: "Quinta-feira",
    value: "thursday",
    open: "1",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
  {
    name: "Sexta-feira",
    value: "friday",
    open: "1",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
  {
    name: "Sábado",
    value: "saturday",
    open: "0",
    start: {
      hour: "18",
      minute: "00",
    },
    end: {
      hour: "23",
      minute: "00",
    },
  },
];
