import { useState } from "react";
import FontAwesome from "../../../../../components/Layout/FontAwesome";
import HttpRequest from "../../../../../misc/classes/HttpRequest/HttpRequest";

const ActionButton = ({ pedido, updateOrder }) => {
  const [loading, setLoading] = useState(false);

  function setPreparing() {
    call("PREPARING");
  }

  function setWaitingDelivering() {
    call("WAITING_DELIVER");
  }

  function setDelivering() {
    call("DELIVERING");
  }

  function setCompleted() {
    call("COMPLETED");
  }

  function call(status) {
    setLoading(true);

    const body = { status };
    HttpRequest.post(
      "/pedidos/" + pedido.id + "/status",
      JSON.stringify(body),
      onSuccess
    );
  }

  function onSuccess(response) {
    setLoading(false);

    updateOrder(response);
  }

  return (
    <>
      {loading && (
        <div className="text-center">
          <div className="spinner-border fsc-1p2 text-success" role="status">
            <span className="visually-hidden">Carregando...</span>
          </div>
        </div>
      )}

      {!loading && (
        <>
          {(pedido.status === "PAID" || pedido.status === "ANALYZING") && (
            <>
              <div
                className="btn btn-primary bg-indigo bg-indigo-hover border-0 text-uppercase fw-500 w-100 py-2"
                onClick={setPreparing}
              >
                <FontAwesome type="solid" icon="check" /> PREPARANDO
              </div>
            </>
          )}

          {pedido.status === "PREPARING" && (
            <>
              <div
                className="btn btn-primary text-uppercase fw-500 w-100 py-2"
                onClick={setWaitingDelivering}
              >
                <FontAwesome type="solid" icon="check" />
                {pedido.address !== null && "Aguardando Entregador"}
                {pedido.address === null && "Aguardando Retirada"}
              </div>
            </>
          )}

          {pedido.status === "WAITING_DELIVER" && pedido.address !== null && (
            <>
              <div
                className="btn btn-primary text-uppercase fw-500 w-100 py-2"
                onClick={setDelivering}
              >
                <FontAwesome type="solid" icon="check" /> Rota de Entrega
              </div>
            </>
          )}

          {((pedido.status === "WAITING_DELIVER" && pedido.address === null) ||
            pedido.status === "DELIVERING") && (
            <>
              <div
                className="btn btn-light border text-uppercase fw-500 w-100 py-2"
                onClick={setCompleted}
              >
                <FontAwesome type="solid" icon="check" /> Concluído
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ActionButton;
