import { forwardRef, useImperativeHandle, useRef } from "react";
import CSS from "./Modal.module.css";

/**
 * @param {string} id
 * @param {boolean} isStatic
 * @param {string} className
 * @param {string} title
 */
const Modal = forwardRef(({id, isStatic, className, title, children}, ref) => {
    const dismissBtn = useRef();
    const openBtn = useRef();

    useImperativeHandle(ref, () => ({
      close, open
    }));

    function close(){
        dismissBtn.current.click();
    }

    function open(){
        openBtn.current.click();
    }

  return (
    <>
      <div ref={openBtn} className={CSS.hidden} data-bs-toggle='modal' data-bs-target={'#'+id}></div>

      <div
        className={"modal fade "+CSS.modal}
        id={id}
        data-bs-backdrop={isStatic && isStatic === true ? 'static' : 'normal'}
        aria-hidden="true"
      >
        <div className={"modal-dialog modal-dialog-centered modal-dialog-scrollable "+CSS.modal_dialog+' '+className}>
          <div className={"modal-content "+CSS.modal_content}>
            <div className="modal-header border-bottom-0">
              <h5 className="modal-title" id="staticBackdropLabel">
                { title ? title : '' }
              </h5>
              <button
                ref={dismissBtn}
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
                {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Modal;