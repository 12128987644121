import Price from "../../../../../misc/classes/Filters/Price";

const Items = ({ pedido }) => {
  return (
    <>
      <div className="fsc-n9 opacity-6">
        <div className="row">
          <div className="col-auto">QTD</div>
          <div className="col">ITEM</div>
          <div className="col-auto">VALOR</div>
        </div>
      </div>

      <div className="fsc-1p2 fsc-m-1 mb-4">
        {pedido.id === null && (
          <div className="placeholder-glow w-100 mt-3">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <>
            {pedido.items.map((item, indexItem) => {
              return (
                <div className="rounded border p-3 my-3" key={indexItem}>
                  <div className="row fw-500" key={indexItem}>
                    <div className="col-auto">
                      <div
                        className="rounded py-1 px-2"
                        style={{
                          backgroundColor: "var(--bs-gray-200)",
                        }}
                      >
                        {item.quantity}
                      </div>
                    </div>
                    <div className="col pt-1 ps-0">{item.name}</div>
                    <div className="col-auto">
                      {Price.numberToCurrency(item.quantity * item.price)}
                    </div>
                  </div>
                  <div className="opacity-7 fsc-n8">{item.ingredients}</div>

                  {item.observations && (
                    <div className="opacity-7 fsc-n7 mt-2 fw-normal">
                      {item.observations}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default Items;
