import "./App.css";
import { useContext } from "react";
import AuthService from "./services/Auth/Auth";
import LoadingShield from "./components/Layout/LoadingShield/LoadingShield";
import OutletFull from "./routes/outlets/OutletFull";
import OutletPainel from "./routes/outlets/OutletPainel";

function App() {
  const authService = useContext(AuthService);

  return (
    <>
        { !authService.logged && <OutletFull /> }
        { authService.logged && <OutletPainel /> }
      
      <LoadingShield />
    </>
  );
}

export default App;
