import BRLInput from "../../../../../components/Layout/Forms/BRLInput";

const MainInfo = ({ pedido }) => {
  return (
    <>
      <div className="main-info">
        <div className="row">
          <div className="col-lg-1 col-12">
            <div className="form-group">
              <label>ID</label>
              <input
                type="text"
                disabled
                className="form-control"
                defaultValue={pedido.id}
              />
            </div>
          </div>

          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Status</label>
              <select
                className="form-select"
                name="status"
                defaultValue={pedido.status}
              >
                <option value="COMPLETED">Concluído</option>
                <option value="DELIVERYING">Rota de Entrega</option>
                <option value="WAITING_DELIVER">
                  Aguardando Entregador/Retirada
                </option>
                <option value="PREPARING">Preparando</option>
                <option value="PAID">Pago</option>
                <option value="ANALYZING">Analisando</option>
                <option value="PROCESSING">Processando</option>
                <option value="CANCELED">Cancelado</option>
              </select>
            </div>
          </div>

          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Data do pedido</label>
              <input
                defaultValue={pedido.date_input}
                className="form-control"
                type="datetime-local"
                name="created_at"
              />
            </div>
          </div>

          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Valor total do pedido</label>
              <BRLInput
                name="total"
                className="form-control"
                defaultValue={pedido.total}
              />
            </div>
          </div>

          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Cupom</label>
              <input
                className="form-control"
                name="cupom"
                defaultValue={pedido.cupom}
              />
            </div>
          </div>

          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Desconto</label>
              <BRLInput
                name="discount"
                className="form-control"
                defaultValue={pedido.discount}
              />
            </div>
          </div>

          {pedido.gateway_fee && (
            <div className="col-lg-2 col-12">
              <div className="form-group">
                <label>Taxa de Processamento</label>
                <BRLInput
                  name="gateway_fee"
                  className="form-control"
                  defaultValue={pedido.gateway_fee}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MainInfo;
