const { Link } = require("react-router-dom");

const Title = (props) => {
  return (
    <div className="row align-items-center">
      <div className="col-12 col-lg-auto">
        <h1 className="text-center text-lg-start">{props.children}</h1>
      </div>
      <div className="col-12 col-lg-auto text-center text-lg-start pt-4 pt-lg-0">
        {(!props.type || props.type === "link") && (
          <Link to={"novo"}>
            <button className="btn btn-outline-primary button-bold fsc-1p2 fsc-m-1">
              {props.btnText ? props.btnText : "Adicionar"}
            </button>
          </Link>
        )}
        {props.type === "function" && (
          <button
            className="btn btn-outline-primary button-bold fsc-1p2 fsc-m-1"
            onClick={props.onClick}
          >
            {props.btnText ? props.btnText : "Adicionar"}
          </button>
        )}
        {props.type === "modal" && (
          <button
            className="btn btn-outline-primary button-bold fsc-1p2 fsc-m-1"
            data-bs-toggle="modal"
            data-bs-target={"#" + props.id}
          >
            {props.btnText ? props.btnText : "Adicionar"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Title;
