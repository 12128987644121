import { useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import routesList, { routesListVisitor } from "./RoutesList";
import AuthService from "../services/Auth/Auth";

const RoutesList = () => {
  const auth = useContext(AuthService);
  const location = useLocation();
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    if (!firstLoad) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      });
    } else {
      setFirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Routes>
        {(auth.username === 'admin' ? routesList : routesListVisitor).map(({ path, index, component }, i) => {
          return (
            <Route key={i} path={path} index={index} element={component} />
          );
        })}

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default RoutesList;
