import { forwardRef } from "react";
import Modal from "../../../../../components/Layout/Modal/Modal";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import ImageAutoUpload from "../../../../../components/shared/Facilitators/ImageAutoUpload";
import FontAwesome from "../../../../../components/Layout/FontAwesome";
import { useRef } from "react";
import CSS from "../IngredientesPhoto.module.css";
import { useContext } from "react";
import ListService from "../../../../../components/shared/Lists/ListService";
import { useImperativeHandle } from "react";

const FormIngredient = forwardRef((props, ref) => {
  const listService = useContext(ListService);

  const [edit, setEdit] = useState(false);
  const modal = useRef();

  const groupIndexRef = useRef();
  const optIndexRef = useRef();
  const nameRef = useRef();
  const priceRef = useRef();
  const imageRef = useRef();
  const descRef = useRef();

  useImperativeHandle(ref, () => ({
    openNew,
    openEdit,
  }));

  function openNew(groupIndex) {
    setEdit(false);
    setModal(groupIndex);
    modal.current.open();
  }

  function openEdit(groupIndex, optIndex) {
    const ingredient = listService.list[groupIndex].ingredients[optIndex];

    setEdit(true);

    setModal(
      groupIndex,
      optIndex,
      ingredient.name,
      ingredient.price,
      '/produtos/ingredientes/'+ingredient.photo,
      ingredient.description
    );

    modal.current.open();
  }

  function setModal(
    groupIndex,
    optIndex = "",
    name = "",
    price = "",
    photo = "",
    description = ""
  ) {
    nameRef.current.value = name;
    priceRef.current.value = price;
    imageRef.current.value(photo);
    groupIndexRef.current.value = groupIndex;
    optIndexRef.current.value = optIndex;
    descRef.current.value = description ? description : "";
  }

  function addOption() {
    const ingredient = {
      name: nameRef.current.value,
      price: priceRef.current.value,
      photo: imageRef.current.getUrl(),
      description: descRef.current.value,
    };

    const groupIndex = groupIndexRef.current.value;

    let newList = listService.copy();
    newList[groupIndex].ingredients.push(ingredient);
    listService.change(newList);

    modal.current.close();
  }

  function editOption() {
    let newList = listService.copy();
    const groupIndex = groupIndexRef.current.value;
    const optIndex = optIndexRef.current.value;

    newList[groupIndex].ingredients[optIndex].name = nameRef.current.value;
    newList[groupIndex].ingredients[optIndex].price = priceRef.current.value;
    newList[groupIndex].ingredients[optIndex].photo = imageRef.current.getTrimUrl();
    newList[groupIndex].ingredients[optIndex].description =
      descRef.current.value;

    listService.change(newList);

    modal.current.close();
  }

  return (
    <>
      <Modal
        id="novo-ingrediente"
        isStatic={true}
        title={edit ? "Editar ingrediente" : "Novo ingrediente"}
        ref={modal}
      >
        <input className="hidden" type="text" ref={groupIndexRef} />
        <input className="hidden" type="text" ref={optIndexRef} />

        <div className="row">
          <div className="col-lg-5 col-12 order-1 order-lg-0">
            <div className="form-group">
              <label>Foto</label>
              <div className={CSS.photo}>
                <ImageAutoUpload ref={imageRef}>
                  <div className={CSS.placeholder}>
                    <FontAwesome icon="camera" type="solid" />
                  </div>
                </ImageAutoUpload>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-12 order-0 order-lg-1">
            <div className="form-group">
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                id="name"
                className="form-control"
                ref={nameRef}
              />
            </div>

            <div className="form-group">
              <label>Adicional</label>
              <div className="row align-items-center">
                <div className="col-auto pe-0">R$</div>
                <div className="col">
                  <NumericFormat
                    className="form-control"
                    displayType="input"
                    valueIsNumericString={true}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    fixedDecimalScale={true}
                    placeholder="0,00"
                    inputMode="decimal"
                    getInputRef={priceRef}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="description">Descrição</label>
          <textarea
            id="description"
            className="form-control"
            ref={descRef}
            style={{ minHeight: "30px" }}
          ></textarea>
        </div>

        <div className="text-end">
          {edit && (
            <div className="btn btn-primary" onClick={editOption}>
              Salvar
            </div>
          )}

          {!edit && (
            <div className="btn btn-primary" onClick={addOption}>
              Adicionar
            </div>
          )}
        </div>
      </Modal>
    </>
  );
});

export default FormIngredient;
