const InputAddress = ({ disabled, pedido, name }) => {
  return (
    <>
      {pedido.address === null && (
        <input disabled={disabled} name={name} className="form-control" />
      )}

      {pedido.address !== null && (
        <input
          disabled={disabled}
          defaultValue={pedido.address !== null ? pedido.address[name] : null}
          name={name}
          className="form-control"
        />
      )}
    </>
  );
};

export default InputAddress;
