import FontAwesome from "../FontAwesome";

const TagNotVisible = () => {
  return (
    <>
      <span title="Não Visível" className="ms-3 fsc-n8 text-danger rounded bg-light p-2">
        <FontAwesome type="solid" icon="eye-slash" />
      </span>
    </>
  );
};

export default TagNotVisible;
