class Price {
  static numberToCurrency(number) {
    return number.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  static currencyToNumber(number) {
    if (number === null || number === "") return 0;

    let price = number.replace(".", "");
    price = price.replace(",", ".");
    price = parseFloat(price);

    return price;
  }
}

export default Price;