import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import CategoriaInformacoes from "./CategoriaInformacoes";
import CategoriaProdutos from "./CategoriaProdutos";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import LoadingPage from "../../../../components/Layout/LoadingPage";
import NavHorizontal from "../../../../components/Layout/NavHorizontal/NavHorizontal";
import Title from "../../../../components/Layout/Title/Title";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import { NavLink } from "react-router-dom";

const Categoria = () => {
  const { categoryId } = useParams();
  const [category, setCategory] = useState({ id: 0 });
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCategory() {
    HttpRequest.get("/categorias/" + categoryId, onSuccessGet, onNotFound);
  }

  function onSuccessGet(data) {
    setCategory(data.category);
    setProducts(data.products);
  }

  function onNotFound() {
    navigate("/categorias");
  }

  function newProduct() {
    navigate("/produtos/novo?categoria=" + categoryId);
  }

  return (
    <LoadingPage condition={category.id > 0}>
      <NavLink to="/categorias" style={{ textDecoration: "unset" }}>
        <div className="mb-4 text-success opacity-7 text-center text-lg-start">
          <span className="me-2">
            <FontAwesome type="solid" icon="chevron-left" />
          </span>
          Voltar para categorias
        </div>
      </NavLink>

      <Title
        notLink={true}
        type="function"
        onClick={newProduct}
        btnText="Adicionar Produto"
      >
        {category.name}
      </Title>

      <NavHorizontal
        classOuter="m-n-content-mobile mt-4"
        routes={[
          {
            name: "Informações",
            to: "",
            end: true,
          },
          {
            name: "Produtos",
            to: "produtos",
          },
        ]}
      />

      <Routes>
        <Route
          path=""
          index={true}
          element={<CategoriaInformacoes category={category} />}
        />
        <Route
          path="produtos"
          element={
            <CategoriaProdutos products={products} categoryId={categoryId} />
          }
        />
      </Routes>
    </LoadingPage>
  );
};

export default Categoria;
