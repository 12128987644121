import Links from "./Links";
import MenuUpload from "./MenuUpload/MenuUpload";

const QrCode = (props) => {
  return (
    <>
      <h1 className="text-center text-lg-start">Qr Code Cardápio</h1>

      <div className="py-2"></div>

      <Links />
      
      <div className="py-2"></div>

      <MenuUpload />
    </>
  );
};

export default QrCode;
