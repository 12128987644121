import { useRef } from "react";
import BRLInput from "../../../../../components/Layout/Forms/BRLInput";
import InputAddress from "./InputAddress";
import { useState } from "react";
import { useEffect } from "react";

const AddressInfo = ({ pedido }) => {
  const addressRef = useRef();
  const [addressState, setAddressState] = useState(false);

  useEffect(() => {
    setAddressState(pedido.address === null ? false : true);
  }, [pedido]);

  function toggleAddress() {
    const value = addressRef.current.value;

    setAddressState(value === "PICKUP" ? false : true);
  }

  return (
    <>
      <div className="address">
        <h5 className="text-uppercase mb-3 text-secondary">Entrega</h5>

        <div className="row">
          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Tipo de Entrega</label>
              <select
                className="form-select"
                name="address"
                defaultValue={pedido.address === null ? "PICKUP" : "DELIVERY"}
                onChange={toggleAddress}
                ref={addressRef}
              >
                <option value="PICKUP">Retirar</option>
                <option value="DELIVERY">Delivery</option>
              </select>
            </div>
          </div>
          <div className="col-lg-2 col-12">
            <div className="form-group">
              <label>Taxa de Entrega</label>
              <BRLInput
                name="delivery_fee"
                className="form-control"
                defaultValue={pedido.delivery_fee}
                inputProps={{ disabled: !addressState }}
              />
            </div>
          </div>
        </div>

        {addressState && (
          <>
            <div className="row">
              <div className="col-lg col-12">
                <div className="form-group">
                  <label>Rua</label>
                  <InputAddress
                    name="logradouro"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>

              <div className="col-lg col-12">
                <div className="form-group">
                  <label>Número</label>
                  <InputAddress
                    name="numero"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>

              <div className="col-lg col-12">
                <div className="form-group">
                  <label>Bairro</label>
                  <InputAddress
                    name="bairro"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>

              <div className="col-lg col-12">
                <div className="form-group">
                  <label>Cidade</label>
                  <InputAddress
                    name="localidade"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>

              <div className="col-lg col-12">
                <div className="form-group">
                  <label>UF</label>
                  <InputAddress
                    name="uf"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>

              <div className="col-lg col-12">
                <div className="form-group">
                  <label>CEP</label>
                  <InputAddress
                    name="cep"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="form-group">
                  <label>Complemento</label>
                  <InputAddress
                    name="complemento"
                    disabled={!addressState}
                    pedido={pedido}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddressInfo;
