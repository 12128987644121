import { useState } from "react";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import { useRef } from "react";

const URLUpload = (props) => {
  const [status, setStatus] = useState(null);
  const formElement = useRef();

  const submitURL = (e) => {
    e.preventDefault();

    setStatus('uploading');

    const formData = new FormData(formElement.current);

    HttpRequest.post("/upload/cardapio", formData, uploadSuccess, uploadError);
  };

  const uploadSuccess = (response) => {
    resetInput();
    setStatus("success");
  };

  const uploadError = (response) => {
    setStatus("error");
  };

  const resetInput = () => {
    formElement.current.reset();
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-2 col-12 fw-bold">Enviar:</div>
        <div className="col">
          <form onSubmit={submitURL} ref={formElement}>
            <input className="form-control mb-3" name="url" required />

            {status !== "uploading" && (
              <>
                <button className="btn btn-success">Salvar URL</button>
              </>
            )}

            {status === "uploading" && (
              <>
                <div className="btn btn-success" disabled>
                  <div
                    className="spinner-border text-light me-2 vertical-middle"
                    style={{ width: "1.5em", height: "1.5em" }}
                    role="status"
                  ></div>
                  Salvando
                </div>
              </>
            )}
          </form>

          {status === "success" && (
            <>
              <div className="alert alert-success d-inline-block fsc-n9 mt-3">
                Arquivo atualizado com sucesso.
              </div>
            </>
          )}
          
          {status === "error" && (
            <>
              <div className="alert alert-danger d-inline-block fsc-n9 mt-3">
                Ocorreu um erro, tente novamente mais tarde.
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default URLUpload;
