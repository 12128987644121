import FontAwesome from "../../../../components/Layout/FontAwesome";
import UpAndDownList from "../../../../components/shared/Lists/UpAndDownList";
import IngredientesLista from "./IngredientesLista";
import CSS from "./Opcoes.module.css";
import { useContext } from "react";
import ModalOptionsService from "./Modals/ModalsProvider";
import { useState } from "react";

const SubGrupo = ({ index, subgroup }) => {
  const modalOptionsService = useContext(ModalOptionsService);
  const [show, setShow] = useState(true);

  function openEdit(){
    modalOptionsService.editSubGroup(index);
  }

  function openDelete(){
    modalOptionsService.deleteSubGroup(index);
  }

  function newIngredient(){
    modalOptionsService.addOption(index);
  }

  function showIngredients(){
    setShow(true);
  }

  function hideIngredients(){
    setShow(false);
  }

  return (
    <>
      <div className={"border rounded my-4 " + CSS.subgrupos}>
        <div
          className={
            "row align-items-center mx-0 py-3 border-bottom " + CSS.subgrupo
          }
        >
          <div className="col-auto px-3">
            <UpAndDownList index={index} />
          </div>
          <div className="col">
            <div className="fw-bold">{subgroup.name}</div>
            <div className="fsc-n8">
              {subgroup.quantity > 0 && (
                <>Escolha até {subgroup.quantity} opções</>
              )}
              {subgroup.quantity === "0" && <>Ilimitado</>}
            </div>
          </div>
          <div className="col-lg-auto col-12 mt-lg-0 mt-2">
            {!show && (
                <div
              className="btn btn-light text-secondary me-2"
              onClick={showIngredients}
            >
              <FontAwesome type="solid" icon="eye" />
            </div>
            )}
            {show && (
                <div
              className="btn btn-light text-secondary me-2"
              onClick={hideIngredients}
            >
              <FontAwesome type="solid" icon="eye-slash" />
            </div>
            )}
            
            <div
              className="btn btn-light text-success me-2 fw-bold"
              onClick={newIngredient}
            >
              + Opção
            </div>
            <div
              className="btn btn-light text-primary me-2"
              onClick={openEdit}
            >
              <FontAwesome type="solid" icon="pencil" />
            </div>
            <div
              className="btn btn-light text-danger"
              onClick={openDelete}
            >
              <FontAwesome type="solid" icon="trash" />
            </div>
          </div>
        </div>
        <div className={!show ? 'd-none' : 'd-block' }>
          <IngredientesLista ingredients={subgroup.ingredients} group={index} />
        </div>
      </div>
    </>
  );
};

export default SubGrupo;
