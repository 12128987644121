import { initializeApp } from "firebase/app";
import { getDatabase, off, onValue, ref } from "firebase/database";
import { useEffect } from "react";
import { useState } from "react";

function useFirebase(initialize = []) {
  const [app, setApp] = useState(null);
  const [database, setDatabase] = useState(null);
  const [listener, setListener] = useState(null);

  useEffect(() => {
    if (app !== null) {
        if(initialize.indexOf('database') > -1){
            initializeDatabase();
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  useEffect(()=>{
    if(listener !== null){
        return removeListener;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listener])

  function loadFirebaseSDK() {
    if(app === null){
        const app = initializeApp(firebaseConfig);
        setApp(app);
    };
  }

  function initializeDatabase() {
    const database = getDatabase(app);
    setDatabase(database);
  }

  function getDatabaseRef(url, onUpdate, onError = null){
    const objectRef = ref(database, url);

    onValue(objectRef, (snapshot)=>{
        const data = snapshot.val();

        const instantOff = ()=>{
            off(objectRef);
            if(process.env.NODE_ENV !== 'production') console.log('listener removed');
        };

        setListener(objectRef);

        onUpdate(data, instantOff);
    }, (error) => {
        if(onError !== null) onError();

        if(process.env.NODE_ENV !== 'production'){
            console.log(error);
        };
    })
  }

  function removeListener(){
    off(listener);
  }

  return {
    firebaseInit: loadFirebaseSDK,
    app,
    database,
    databaseFunc: {
        getRef: getDatabaseRef
    }
  };
}

export default useFirebase;

const firebaseConfig = {
  apiKey: "AIzaSyCwRtUA7w6euZBqYNAeJwtLxaf9znYNfP4",
  authDomain: "levolpi-23a7d.firebaseapp.com",
  projectId: "levolpi-23a7d",
  storageBucket: "levolpi-23a7d.appspot.com",
  messagingSenderId: "175712024460",
  appId: "1:175712024460:web:e35ad19e7facbd108308a9",
  databaseURL: 'https://levolpi-23a7d-default-rtdb.firebaseio.com/'
};
