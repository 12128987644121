import Admin from "./Admin";
import Visitor from "./Visitor";

const Acesso = () => {
  return (
    <>
      <h1>Acesso ao Painel</h1>

      <div className="py-2"></div>

      <Admin />
      
      <div className="py-2"></div>

      <Visitor />
    </>
  );
};

export default Acesso;
