import { useRef } from "react";
import { useState } from "react";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";

const FileUpload = (props) => {
    
  const [status, setStatus] = useState(null);
  const inputRef = useRef();

  const triggerInput = () => {
    inputRef.current.click();
  };

  const fileChanged = () => {
    let file = inputRef.current.files;

    if (file.length !== 1) {
      return;
    }

    uploadFile(file[0]);
  };

  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append("file", file);

    setStatus("uploading");

    HttpRequest.post("/upload/cardapio", formData, uploadSuccess, uploadError);
  };

  const uploadSuccess = (response) => {
    resetInput();
    setStatus("success");
  };

  const uploadError = (response) => {
    resetInput();
    setStatus("error");
  };

  const resetInput = () => {
    inputRef.current.value = null;
  };

  return (
    <>
      <div className="row mb-4">
        <div className="col-lg-2 col-12 fw-bold">Enviar:</div>
        <div className="col">
          {status !== "uploading" && (
            <>
              <div className="btn btn-success" onClick={triggerInput}>
                <FontAwesome icon="file-arrow-up" /> Enviar arquivo
              </div>
            </>
          )}

          {status === "uploading" && (
            <>
              <div className="btn btn-success" disabled>
                <div
                  className="spinner-border text-light me-2 vertical-middle"
                  style={{ width: "1.5em", height: "1.5em" }}
                  role="status"
                ></div>
                Enviando arquivo
              </div>
            </>
          )}

          <input
            type="file"
            ref={inputRef}
            onChange={fileChanged}
            multiple={false}
            accept=".pdf, .jpg, .jpeg, .png"
            className="hidden"
          />

          <div
            className={`fsc-n8 mt-2 ${
              status === "error" ? "text-danger" : "text-secondary"
            }`}
          >
            Tipos aceitos: PDF, JPG, JPEG, PNG
          </div>

          {status === "success" && (
            <>
              <div className="alert alert-success d-inline-block fsc-n9 mt-3">
                Arquivo atualizado com sucesso.
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FileUpload;
