import CSS from './Tags.module.css';

const PaymentTag = ({payment}) => {
    const accepted = ['pix', 'credit', 'dinheiro'];

   return (
       <>
       <div className={'rounded px-2 py-1 fsc-n9 d-inline-block opacity-7 fw-500 ' + CSS[payment]}>
         {payment === "pix" && "Pix (site)"}
         {payment === "credit" && "Cartão de crédito (site)"}
         {payment === "dinheiro" && "Dinheiro (na entrega)"}
         {accepted.indexOf(payment) === -1 && "Cartões (na entrega)"}
       </div>           
       </>
   )
}

export default PaymentTag;