import { useEffect } from "react";
import { useRef } from "react";

const TelInput = ({ name, className, defaultValue, inputProps }) => {
  const realValueRef = useRef();
  const formatedValueRef = useRef();

  useEffect(() => {
    realValueRef.current.value = defaultValue;
    formatTel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  function setRealValue() {
    const value = formatedValueRef.current.value;
    const realValue = value
      .replace(/ /g, "")
      .replace(/\(/g, "")
      .replace(/\)/g, "")
      .replace(/-/g, "");

    realValueRef.current.value = realValue;
  }

  function formatTel() {
    const realValue = realValueRef.current.value;

    let newValue = "";

    for (let i = 0; i < realValue.length; i++) {
      if (i === 0) {
        newValue += "(";
      }
      if (i === 2) {
        newValue += ") ";
      }
      if ((realValue.length === 11 && i === 7) || (realValue.length < 11 && i === 6) ) {
        newValue += "-";
      }
      newValue += realValue.charAt(i);
    }

    formatedValueRef.current.value = newValue;
  }

  function handleTyping(){
    setRealValue();
    formatTel();
  }

  return (
    <>
      <input ref={realValueRef} className="hidden" name={name} />
      <input
        ref={formatedValueRef}
        className={className}
        onChange={handleTyping}
        {...inputProps}
        autoComplete="telephone"
        inputMode="number"
        pattern="[0-9\s\-\(\)]{14}|[0-9\s\-\(\)]{15}"
        maxLength={15}
      />
    </>
  );
};

export default TelInput;
