import FontAwesome from "../../../../../../components/Layout/FontAwesome";
import Price from "../../../../../../misc/classes/Filters/Price";

const PrintOrder = ({ pedido }) => {
  const body = `<!DOCTYPE html>
    <html>
    <head>
      <title>Impressão do Pedido</title>
      <style>
        body {
          font-family: Arial, sans-serif;
        }

        h1, h2 {
            margin: .25rem 0;
        }

        h1 {font-size: 1.2em;}
        h2 {font-size: 1em;}

        p {margin: .25em 0;}
    
        .container {
          max-width: 500px;
          margin: 0 auto;
          padding: 10px;
        }
    
        .header {
          margin-bottom: 15px;
        }
    
        .items-table {
          width: 100%;
          margin-bottom: 10px;
        }
    
        .items-table th,
        .items-table td {
          padding: 5px;
            text-align: left;
        }

        tr {vertical-align: top;}

        .cell-min {
            width: 1px;
            white-space: nowrap;
        }

        .ingredients, .observations {
            font-size: .8em;
        }
    
        .valores {
          text-align: right;
          margin-bottom: 2px;
        }
    
        .customer-info {
          margin-bottom: 15px;
        }
    
        .delivery-address {
          margin-bottom: 15px;
        }
    
        .footer {
          margin-top: 10px;
        }
      </style>
    </head>
    <body>
      <div class="container">
        <div class="header">
          <h2>Pedido #${pedido.id} pelo site</h2>
          <p><strong>Data e hora:</strong> ${pedido.date}</p>
        </div>
        
        <div class="content">
          <div class="customer-info">
            <p><strong>Nome:</strong> ${pedido.name}</p>
            <p><strong>Telefone:</strong> ${pedido.tel_formated}</p>
            <p><strong>CPF/CNPJ:</strong> ${pedido.document_formated}</p>
          </div>
          
          <div class="delivery-address">
            <p><strong>Endereço de Entrega:</strong></p>

            ${
              pedido.address !== null
                ? `<p>${pedido.address.logradouro}, ${pedido.address.numero} - ${pedido.address.bairro} - ${pedido.address.localidade} - ${pedido.address.uf}</p>`
                : `<p>Cliente escolheu retirar o pedido no restaurante</p>`
            }
        
          </div>
          
          <table class="items-table">
            <thead>
              <tr>
                <th>Qtd</th>
                <th>Item</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
            ${pedido.items.map((item, indexItem) => {
              return `<tr>
                          <td class='cell-min'>${item.quantity}</td>
                          <td>
                          <div>${item.name}</div>
                          <div class='ingredients'>${item.ingredients}</div>
                          ${
                            item.observations
                              ? `<div class='observations'>${item.observations}</div>`
                              : ""
                          }                                                                 
                        </td>
                          <td class='cell-min'>${Price.numberToCurrency(
                            item.quantity * item.price
                          )}</td>
                        </tr>`;
            })}
            </tbody>
          </table>
          
            <div class="valores">
                <strong>Subtotal:</strong> ${Price.numberToCurrency(
                  pedido.total - pedido.delivery_fee - pedido.discount
                )}
            </div>
            ${
              pedido.address !== null
                ? `<div class="valores">
                        <strong>Taxa de entrega:</strong> ${Price.numberToCurrency(
                          pedido.delivery_fee
                        )}
                    </div>`
                : ""
            }

            ${
              pedido.discount > 0
                ? `<div class="valores">
                        <strong>Desconto:</strong> ${Price.numberToCurrency(
                          pedido.discount
                        )}
                    </div>`
                : ""
            }
            
            <div class="valores">
                <strong>Total:</strong> ${Price.numberToCurrency(pedido.total)}
            </div>
        </div>

        <div class='footer'>
            <p><strong>Pagamento:</strong></p>
            <p>
                ${
                  pedido.payment === "LOCAL"
                    ? "Pagamento na entrega - " +
                      localPaymentMethods[pedido.payment_method].name
                    : ""
                }
                ${
                  pedido.payment === "DIRECT"
                    ? "Pagamento pelo site - " +
                      (pedido.payment_method === "credit" ? "Crédito" : "") +
                      (pedido.payment_method === "pix" ? "Pix" : "")
                    : ""
                }
            </p>
        </div>
      </div>
    </body>
    </html>`;

  function printIt() {
    const printWindow = window.open("", "_blank");

    printWindow.document.write(body);

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  return (
    <>
      <div
        className="btn btn-light border text-uppercase fw-500 text-secondary w-100 py-2 px-4"
        onClick={printIt}
      >
        <FontAwesome type="solid" icon="print" /> Imprimir
      </div>
    </>
  );
};

export default PrintOrder;

const localPaymentMethods = {
  dinheiro: {
    name: "Dinheiro",
    image: "/img/pagamento/dinheiro.png",
  },
  master_debito: {
    name: "Mastercard - Débito",
    image: "/img/pagamento/mastercard.png",
  },
  visa_debito: {
    name: "Visa - Débito",
    image: "/img/pagamento/visa.png",
  },
  elo_debito: {
    name: "Elo - Débito",
    image: "/img/pagamento/elo.png",
  },
  visa_credito: {
    name: "Visa - Crédito",
    image: "/img/pagamento/visa.png",
  },
  mastercard_credito: {
    name: "Mastercard - Crédito",
    image: "/img/pagamento/mastercard.png",
  },
  elo_credito: {
    name: "Elo - Crédito",
    image: "/img/pagamento/elo.png",
  },
  amex_credito: {
    name: "Amex - Crédito",
    image: "/img/pagamento/amex.png",
  },
  ticket: {
    name: "Ticket - Vale-Refeição",
    image: "/img/pagamento/ticket.png",
  },
};
