import { useContext, useEffect } from "react";
import ListService from "../../../../components/shared/Lists/ListService";
import { useNavigate } from "react-router-dom";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import Form from "../../../../components/shared/Facilitators/Form";
import CategoriasSelect from "../../Categorias/CategoriasSelect";
import { NumericFormat } from "react-number-format";
import FontAwesome from "../../../../components/Layout/FontAwesome";
import CSS from "../../Produtos/ProdutosNovo.module.css";
import ImageAutoUpload from "../../../../components/shared/Facilitators/ImageAutoUpload";
import { useState } from "react";
import Opcoes from "../Opcoes/Opcoes";
import ButtonLoading from "../../../../components/Layout/ButtonLoading";
import { useRef } from "react";
import Promocao from "../Promocao/Promocao";

const ProdutoNovoInner = (props) => {
  const listService = useContext(ListService);
  const navigate = useNavigate();
  const [categoriaParam, setCategoriaParam] = useState();
  const btnSave = useRef();
  const promocaoRef = useRef();
  const priceRef = useRef();
  const [price, setPrice] = useState(0);
  const [inPromocao, setInPromocao] = useState(false);

  useEffect(() => {
    if (promocaoRef.current) {
      promocaoRef.current.toggle(inPromocao);
    }
  }, [inPromocao]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setTimeout(() => {
      setCategoriaParam(params.get("categoria"));
    }, 100);
  }, []);

  function onSubmit(formData) {
    btnSave.current.setLoading(true);
    formData.append("ingredients", listService.json());
    HttpRequest.post("/produtos", formData, onSuccess);
  }

  function onSuccess(response) {
    btnSave.current.setLoading(false);
    navigate("/produtos/" + response.produto);
  }

  function togglePromocao() {
    setInPromocao(!inPromocao);
  }

  function priceChanged() {
    const newPrice = priceRef.current.value;
    setPrice(newPrice);
  }

  return (
    <>
      <h1>Novo Produto</h1>

      <div className="mt-5"></div>

      <Form className="box-widget m-n-content-mobile" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="name">Titulo</label>
              <input
                type="text"
                name="name"
                id="name"
                className="form-control"
                required
              />
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="category">Categoria</label>
              <CategoriasSelect defaultValue={categoriaParam} required={true} />
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="price">Preço</label>
              <div className="row align-items-center">
                <div className="col-auto pe-0">R$</div>
                <div className="col">
                  <NumericFormat
                    id={"price"}
                    name={"price"}
                    className="form-control"
                    displayType="input"
                    valueIsNumericString={true}
                    decimalSeparator={","}
                    thousandSeparator={"."}
                    fixedDecimalScale={true}
                    placeholder="0,00"
                    inputMode="decimal"
                    onChange={priceChanged}
                    getInputRef={priceRef}
                  />
                </div>
                <div className="col-auto">
                  <div
                    className={
                      "btn " +
                      (inPromocao ? "btn-warning" : "btn-outline-warning")
                    }
                    onClick={togglePromocao}
                  >
                    {inPromocao && (
                      <FontAwesome icon="square-check" type="regular" />
                    )}
                    {!inPromocao && (
                      <FontAwesome icon="square" type="regular" />
                    )}

                    <span className="ms-2 fw-bold">Promoção</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {inPromocao && <Promocao defaultPrice={price} ref={promocaoRef} />}
        {!inPromocao && (
          <input
            name="discount"
            type="text"
            className="hidden"
            defaultValue="0"
          />
        )}

        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="visible">Visível</label>
              <select
                className="form-select"
                id="visible"
                name="visible"
                defaultValue={1}
              >
                <option value="1">Sim</option>
                <option value="0">Não</option>
              </select>
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="visible">Destaque</label>
              <select
                className="form-select"
                id="visible"
                name="destaque"
                defaultValue={0}
              >
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
            </div>
          </div>

          <div className="col-lg-4 col-12">
            <div className="form-group">
              <label htmlFor="category">Serve até</label>
              <div className="row align-items-center">
                <div className="col">
                  <input
                    name="people"
                    type="number"
                    min={0}
                    defaultValue={0}
                    className="form-control"
                  />
                </div>
                <div className="col-auto">pessoas</div>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="description">Descrição</label>
          <textarea
            type="text"
            name="description"
            id="description"
            className="form-control"
          ></textarea>
        </div>

        <div className="form-group">
          <div className="fw-bold">Foto</div>

          <div className={CSS.photos + " mt-2"}>
            <div className="row">
              <div className="col-lg-auto col-12">
                <ImageAutoUpload name="photo">
                  <div className={CSS.photos_placeholder}>
                    <FontAwesome icon="camera" type="solid" />
                  </div>
                </ImageAutoUpload>
              </div>
            </div>
          </div>
        </div>

        <div className="form-group">
          <label>Opções</label>
          <Opcoes />
        </div>

        <div className="row mt-5">
          <div className="col-lg-auto col-12">
            <ButtonLoading ref={btnSave} className="text-primary">
              <button className="btn btn-primary fw-bold w-100 px-4">
                Salvar
              </button>
            </ButtonLoading>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ProdutoNovoInner;
