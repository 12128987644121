import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useRef } from "react";

const Form = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({ ref: formRef }));

  const formRef = useRef();

  function onSubmit(e) {
    e.preventDefault();

    const formData = new FormData(formRef.current);

    if (props.onSubmit) props.onSubmit(formData);
  }

  return (
    <form onSubmit={onSubmit} ref={formRef} className={props.className}>
      {props.method && props.method !== "POST" && (
        <input type="hidden" name="_method" value={props.method} />
      )}
      {props.children}
    </form>
  );
});

export default Form;
