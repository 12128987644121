import FullList from "../../Pedidos/FullList/FullList";

const ClientePedidos = ({customer}) => {
   return (
    <>
        <FullList user={customer} />
    </>
   )
}

export default ClientePedidos;