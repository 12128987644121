import { useEffect, useRef } from "react";
import { ListServiceProvider } from "../../../components/shared/Lists/ListService";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import { useState } from "react";
import ClientesLista from "./ClientesLista";
import Pagination from "../../../components/Layout/Pagination/Pagination";
import Search from "../../../components/shared/Lists/Search/Search";

const Clientes = (props) => {
  const [list, setList] = useState([]);
  const paginationRef = useRef();
  const [pagination, setPagination] = useState(null);
  const [count, setCount] = useState(0);

  useEffect(() => {
    getClientes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getClientes(search = null) {
    const pages =
      paginationRef.current.fetchParam() !== null
        ? paginationRef.current.fetchParam()
        : "";

    const searchParam = search === null ? "" : "&search=" + search;

    HttpRequest.get("/clientes?" + pages + searchParam, onSuccess);
  }

  function onSuccess(data) {
    setList(data.list);
    if (data.pages) setPagination(data.pages);
    if (data.count) setCount(data.count);
  }

  return (
    <>
      <h1 className="text-center text-lg-start">Clientes</h1>

      <div className="mt-5"></div>

      <ListServiceProvider list={list}>
        <Search onSearch={getClientes} />
          <div className="mt-2 text-secondary">Clientes encontrados: <b>{count}</b></div>

        <div className="my-4"></div>

        <Pagination
          ref={paginationRef}
          onChange={getClientes}
          paginas={pagination}
        >
          <ClientesLista />
        </Pagination>
      </ListServiceProvider>
    </>
  );
};

export default Clientes;
