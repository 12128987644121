import { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Layout/Modal/Modal";
import Form from "../../../components/shared/Facilitators/Form";
import http from "../../../misc/classes/HttpRequest/HttpRequest";
import Alert from "../../../components/Layout/Alert/Alert";
import ButtonLoading from "../../../components/Layout/ButtonLoading";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import FontAwesome from "../../../components/Layout/FontAwesome";
import BRLInput from "../../../components/Layout/Forms/BRLInput";

const CuponsNova = forwardRef((props, ref) => {
  const modal = useRef();
  const btnSave = useRef();
  const btnDel = useRef();

  const idRef = useRef();
  const nameRef = useRef();
  const qtdRef = useRef();
  const expireRef = useRef();
  const typeRef = useRef();
  const limitDocumentRef = useRef();

  const [errorMessage, setErrorMessage] = useState();
  const errorAlertRef = useRef();

  const [statusQtd, setStatusQtd] = useState(false);
  const [statusExpire, setStatusExpire] = useState(false);
  const [statusType, setStatusType] = useState("percentage");
  const [editMode, setEditMode] = useState(false);

  const [defaultDiscount, setDefaultDiscount] = useState(10);

  useImperativeHandle(ref, () => ({
    open,
  }));

  useEffect(()=>{
    if(editMode){
        idRef.current.value = editMode;
    }
  }, [editMode]);

  function open(cupom = null) {
    clearError();

    setStatusExpire(false);
    setStatusQtd(false);
    setStatusType("percentage");
    setEditMode(false);
    setDefaultDiscount(10);

    nameRef.current.value = "";
    qtdRef.current.value = "1";
    expireRef.current.value = "";
    typeRef.current.value = "percentage";
    limitDocumentRef.current.value = '0';

    if (cupom !== null) {
      editCupom(cupom);
    }

    modal.current.open();
  }

  function editCupom(cupom) {
    setEditMode(cupom.id);

    if (cupom.expire !== null) {
      setStatusExpire(true);
    }

    if (cupom.quantity !== null) {
      setStatusQtd(true);
    }

    setStatusType(cupom.type);

    nameRef.current.value = cupom.name;
    qtdRef.current.value = cupom.quantity;
    expireRef.current.value = cupom.expire;
    typeRef.current.value = cupom.type;
    limitDocumentRef.current.value = cupom.limit_document;

    if (cupom.discount !== null) {
      setDefaultDiscount(cupom.discount);
    }
  }

  function onSubmit(formData) {
    clearError();
    btnSave.current.setLoading(true);
    http.post("/cupons", formData, onSuccess, onError);
  }

  function onSuccess(data) {
    btnSave.current.setLoading(false);
    if(btnDel.current){
        btnDel.current.setLoading(false);
    }
    modal.current.close();
    if (props.onSuccess) props.onSuccess(data);
  }

  function onError(error) {
    btnSave.current.setLoading(false);
    setErrorMessage(error.message);
    errorAlertRef.current.open();
  }

  function clearError() {
    setErrorMessage(null);
    errorAlertRef.current.close();
  }

  function toggleQtd() {
    setStatusQtd(!statusQtd);
  }

  function toggleExpire() {
    setStatusExpire(!statusExpire);
  }

  function changeType() {
    const value = typeRef.current.value;

    setStatusType(value);
  }

  function deleteCupom(){
    const form = new FormData();
    form.append('_method', 'DELETE');
    form.append('id', editMode);

    http.post("/cupons", form, onSuccess, onError);
  }

  return (
    <>
      <Modal
        id="novo-cupom"
        title={editMode ? "Editar Cupom" : "Novo Cupom"}
        ref={modal}
      >
        <Form onSubmit={onSubmit}>
          {editMode && (
            <input name="id" ref={idRef} type='hidden' className="text" />
          )}

          <div className="form-group">
            <label>Nome</label>
            <input
              name="name"
              id="name"
              type="text"
              required
              className="form-control"
              ref={nameRef}
            />
          </div>

          <div className="form-group">
            <label>Desconto</label>
            <div className="row align-itens-center">
              <div className="col-lg-6 col-12 mb-2 mb-lg-0">
                <select
                  name="type"
                  className="form-select"
                  onChange={changeType}
                  ref={typeRef}
                >
                  <option value="percentage">Porcentagem Total</option>
                  <option value="fixed">Valor Fixo</option>
                  <option value="delivery">Frete Grátis</option>
                </select>
              </div>
              <div className="col-lg-6 col-12">
                {statusType === "percentage" && (
                  <>
                    <div className="row align-items-center">
                      <div className="col">
                        <input
                          name="discount"
                          type="number"
                          defaultValue={defaultDiscount}
                          className="form-control"
                          min="1"
                          max="100"
                        />
                      </div>
                      <div className="col-auto fsc-1p2 ps-0">
                        <FontAwesome icon="percentage" />
                      </div>
                    </div>
                  </>
                )}
                {statusType === "fixed" && (
                  <BRLInput
                    name="discount"
                    className="form-control"
                    defaultValue={defaultDiscount}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg col-12">
              <div className="form-group">
                <label>Quantidade</label>
                <div className="row align-itens-center">
                  <div className="col-auto pe-0">
                    <div className="cursor-pointer fsc-1p5" onClick={toggleQtd}>
                      {statusQtd && (
                        <div className="text-primary">
                          <FontAwesome icon="toggle-on" />
                        </div>
                      )}
                      {!statusQtd && (
                        <div className="text-secondary">
                          <FontAwesome icon="toggle-off" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <input
                      name={statusQtd ? "quantity" : ""}
                      type="number"
                      className="form-control"
                      ref={qtdRef}
                      defaultValue="1"
                      min='0'
                      disabled={!statusQtd}
                      required={statusQtd}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg col-12">
              <div className="form-group">
                <label>Expira em</label>
                <div className="row align-itens-center">
                  <div className="col-auto pe-0">
                    <div
                      className="cursor-pointer fsc-1p5"
                      onClick={toggleExpire}
                    >
                      {statusExpire && (
                        <div className="text-primary">
                          <FontAwesome icon="toggle-on" />
                        </div>
                      )}
                      {!statusExpire && (
                        <div className="text-secondary">
                          <FontAwesome icon="toggle-off" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col">
                    <input
                      name={statusExpire ? "expire" : ""}
                      type="date"
                      className="form-control"
                      ref={expireRef}
                      min={statusExpire ? "1" : ""}
                      disabled={!statusExpire}
                      required={statusExpire}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="form-group">
            <label>Limite por CPF/CNPJ</label>
            <select name='limit_document' className="form-select" ref={limitDocumentRef}>
                <option value='0'>Não</option>
                <option value='1'>Sim</option>
            </select>
          </div>

          <div className="row justify-content-end">
            {editMode && (
              <>
                <div className="col">
                  <ButtonLoading ref={btnDel} className="text-primary">
                    <div className="btn btn-danger" onClick={deleteCupom}>
                      Excluir
                    </div>
                  </ButtonLoading>
                </div>
              </>
            )}
            <div className="col-auto">
              <ButtonLoading ref={btnSave} className="text-primary">
                <button className="btn btn-primary">
                  {editMode ? "Atualizar" : "Adicionar"}
                </button>
              </ButtonLoading>
            </div>
          </div>

          <Alert
            className="alert-danger mt-3"
            dismissible
            ref={errorAlertRef}
            icon="triangle-exclamation"
          >
            {errorMessage}
          </Alert>
        </Form>
      </Modal>
    </>
  );
});

export default CuponsNova;
