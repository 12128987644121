import Ingrediente from "./Ingrediente";

const IngredientesLista = ({ group, ingredients }) => {
  return (
    <>
      <div className="fsc-m-n8">
        <div
          className={"table-responsive"}
        >
          <table className="table table-style table-hover mb-0">
            <tbody className="table-group-divider align-middle bg-white border-top">
              {ingredients.map((ingredient, index) => {
                return (
                  <Ingrediente
                    group={group}
                    ingredient={ingredient}
                    key={index}
                    index={index}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

    </>
  );
};

export default IngredientesLista;
