import StatusTags from "../../Templates/StatusTags";

const Status = ({ pedido }) => {
  return (
    <>
        <h4 className="text-success opacity-5 fsc-1p2">Status do pedido</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
            <div className="fsc-1p2 fsc-m-1">
                <StatusTags status={pedido.status} address={pedido.address} />
            </div>
        )}
    </>
  );
};

export default Status;
