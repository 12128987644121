import { ListServiceProvider } from "../../../../components/shared/Lists/ListService";
import ProdutosLista from "../../Produtos/ProdutosLista";
import SearchList from "../../../../components/shared/Lists/SearchList/SearchList";
import SaveProductsPositions from "../SaveProductsPositions";

const CategoriaProdutos = ({ products, categoryId }) => {

  return (
    <>
      <ListServiceProvider
        list={products}
        searchIn={["name", "category_name", "description"]}
      >

        <SearchList />
        <div className="mb-2 mb-lg-4"></div>
        <ProdutosLista noCategory={true} ordering={true} />

        <div className="d-block d-lg-inline-block my-5 text-center">
            <SaveProductsPositions categoryId={categoryId} />
        </div>
      </ListServiceProvider>
    </>
  );
};

export default CategoriaProdutos;
