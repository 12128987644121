import NavHorizontal from "../../../components/Layout/NavHorizontal/NavHorizontal";

const Header = (props) => {
   return (
       <>
       <h1 className="text-center text-lg-start">Pedidos</h1>
 
       <NavHorizontal
         classOuter="m-n-content-mobile mt-4"
         routes={[
           {
             name: "Ativos",
             to: "/pedidos",
             end: true,
           },
           {
             name: "Todos",
             to: "/pedidos/todos",
           },
         ]}
       />
           
       </>
   )
}

export default Header;