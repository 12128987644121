import { ListServiceProvider } from "../../../../components/shared/Lists/ListService";
import ProdutoNovoInner from "./ProdutoNovoInner";

const ProdutoNovo = () => {
  return (
    <>
      <ListServiceProvider skipSecondList={true} list={[]}>
        <ProdutoNovoInner />
      </ListServiceProvider>
    </>
  );
};

export default ProdutoNovo;
