import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";

import "./css/css";

import App from "./App";
import { AuthServiceProvider } from "./services/Auth/Auth";
import { SessionServiceProvider } from "./services/Session/Session";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <SessionServiceProvider>
        <AuthServiceProvider>
          <App />
        </AuthServiceProvider>
      </SessionServiceProvider>
    </BrowserRouter>
  </>
);
