import { useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import Form from "../../../../components/shared/Facilitators/Form";
import ButtonLoading from "../../../../components/Layout/ButtonLoading";
import Modal from "../../../../components/Layout/Modal/Modal";
import CategoriasSelect from "../CategoriasSelect";

const CategoriaInformacoes = ({category}) => {
  const btnSave = useRef();
  const btnDel = useRef();
  const [deleteActivated, setDeleteActivated] = useState(false);
  const delModal = useRef();
  const navigate = useNavigate();
    
  function onSubmit(formData) {
    btnSave.current.setLoading(true);
    HttpRequest.post("/categorias/" + category.id, formData, onSuccessSubmit);
  }

  function onSuccessSubmit(data) {
    btnSave.current.setLoading(false);
  }

  function activateDelete() {
    if (!deleteActivated) {
      setDeleteActivated(true);
    }
  }

  function deleteCategory(formData) {
    btnDel.current.setLoading(true);
    HttpRequest.post("/categorias/" + category.id, formData, deletedSuccess);
  }

  function deletedSuccess() {
    delModal.current.close();
    onNotFound();
  }
  
  function onNotFound() {
    navigate("/categorias");
  }

   return (
       <>
       <Form
       className='box-widget m-n-content-mobile'
         onSubmit={onSubmit}
         method="PUT"
       >
         <div className="row">
           <div className="col-lg col-12">
             <div className="form-group">
               <label htmlFor="name">Nome</label>
               <input
                 id="name"
                 name="name"
                 type="text"
                 required
                 className="form-control"
                 defaultValue={category.name}
               />
             </div>
           </div>
           <div className="col-lg-3 col-12">
             <div className="form-group">
               <label htmlFor="visible">Visível</label>
               <select className="form-select" id="visible" name="visible" defaultValue={category.visible}>
                 <option value="1">Sim</option>
                 <option value="0">Não</option>
               </select>
             </div>
           </div>
         </div>

         <div className="row justify-content-between">
           <div className="col-lg-auto col-12 mb-3 mb-lg-0">
             <ButtonLoading ref={btnSave} className="text-primary">
               <button
                 type="submit"
                 className="btn btn-primary fw-bold w-100 px-4"
               >
                 Salvar
               </button>
             </ButtonLoading>
           </div>
           <div className="col-lg-auto col-12">
             <div
               className="btn btn-outline-danger fw-bold w-100"
               data-bs-toggle="modal"
               data-bs-target="#deletar-categoria"
               onClick={activateDelete}
             >
               Deletar
             </div>
           </div>
         </div>
       </Form>

       <Modal id="deletar-categoria" title="Deletar Categoria" ref={delModal}>
         <p>
           Para qual categoria você deseja mover os produtos desta categoria?
         </p>

         <Form method="DELETE" onSubmit={deleteCategory}>
           {deleteActivated && (
             <CategoriasSelect except={[category.id]} required={true} />
           )}

           <div className="row justify-content-between mt-5">
             <div className="col-auto">
               <div className="btn btn-secondary" data-bs-dismiss="modal">
                 Cancelar
               </div>
             </div>
             <div className="col-auto">
               <ButtonLoading className="text-danger" ref={btnDel}>
                 <button type="submit" className="btn btn-danger">
                   Deletar
                 </button>
               </ButtonLoading>
             </div>
           </div>
         </Form>
       </Modal>
       </>
   )
}

export default CategoriaInformacoes;