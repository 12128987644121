import { useContext } from "react";
import ListService from "../../../../components/shared/Lists/ListService";
import SubGruposLista from "./SubGruposLista";
import { ModalOptionsServiceProvider } from "./Modals/ModalsProvider";
import CSS from './Opcoes.module.css';

const Opcoes = ({ edit }) => {
  const listService = useContext(ListService);

  return (
    <>
      <div className={"border rounded px-lg-4 py-4 "+CSS.box}>
        <ModalOptionsServiceProvider>
            <SubGruposLista edit={edit} />
        </ModalOptionsServiceProvider>

        {edit && listService.touched && (
          <div className="alert alert-warning">
            Você possui mudanças não salvas.
          </div>
        )}
      </div>
    </>
  );
};

export default Opcoes;
