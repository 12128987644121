import { useRef, useState } from "react";
import Modal from "../../../components/Layout/Modal/Modal";
import Form from "../../../components/shared/Facilitators/Form";
import http from "../../../misc/classes/HttpRequest/HttpRequest";
import Alert from "../../../components/Layout/Alert/Alert";
import ButtonLoading from "../../../components/Layout/ButtonLoading";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";

const CategoriaNova = forwardRef((props, ref) => {
    const modal = useRef();
    const btnSave = useRef();
    const nameRef = useRef();
    const [errorMessage, setErrorMessage] = useState();
    const errorAlertRef = useRef();

    useImperativeHandle(ref, ()=>({
        open
    }));

    function open(){
        clearError();
        nameRef.current.value = '';
        modal.current.open();
    }

    function onSubmit(formData) {
        clearError();
        btnSave.current.setLoading(true);
        http.post('/categorias', formData, onSuccess, onError);
    }

    function onSuccess(data){
        btnSave.current.setLoading(false);
        modal.current.close();
        if(props.onSuccess) props.onSuccess(data);
    }

    function onError(error){
        btnSave.current.setLoading(false);
        setErrorMessage(error.message);
        errorAlertRef.current.open();
    }

    function clearError(){
        setErrorMessage(null);
        errorAlertRef.current.close();
    }

  return (
    <>
      <Modal id="nova-categoria" title="Nova Categoria" ref={modal}>
        <Form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nome</label>
            <input name="name" id="name" type="text" required className="form-control" ref={nameRef} />
          </div>
          <div className="text-end">
            <ButtonLoading ref={btnSave} className='text-primary'>
                <button className="btn btn-primary">Adicionar</button>
            </ButtonLoading>
          </div>

          <Alert className='alert-danger mt-3' dismissible ref={errorAlertRef} icon='triangle-exclamation'>
            {errorMessage}
          </Alert>
        </Form>
      </Modal>
    </>
  );
});

export default CategoriaNova;
