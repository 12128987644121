import { createContext } from "react";
import FormSubGrupo from "./FormSubGrupo";
import { useRef } from "react";
import DeleteSubGroup from "./DeleteSubGrupo";
import FormIngredient from "./FormIngredient";
import DeleteIngredient from "./DeleteIngredient";

const ModalOptionsService = createContext({
  addSubGroup: () => {},
  editSubGroup: (groupIndex) => {},
  deleteSubGroup: (groupIndex) => {},
  addOption: (groupIndex) => {},
  editOption: (groupIndex, optionIndex) => {},
  deleteOption: (groupIndex, optionIndex) => {},
});
export default ModalOptionsService;

export function ModalOptionsServiceProvider({ children }) {
  const formSubGroupModal = useRef();
  const deleteSubGroupModal = useRef();
  const formIngredientModal = useRef();
  const deleteIngredientModal = useRef();

  function addSubGroup() {
    formSubGroupModal.current.openNew();
  }

  function editSubGroup(groupIndex) {
    formSubGroupModal.current.openEdit(groupIndex);
  }

  function deleteSubGroup(groupIndex) {
    deleteSubGroupModal.current.open(groupIndex);
  }

  function addOption(groupIndex) {
    formIngredientModal.current.openNew(groupIndex);
  }

  function editOption(groupIndex, optIndex) {
    formIngredientModal.current.openEdit(groupIndex, optIndex);
  }

  function deleteOption(groupIndex, optIndex){
    deleteIngredientModal.current.open(groupIndex, optIndex);
  }

  const context = {
    addSubGroup: addSubGroup,
    editSubGroup: editSubGroup,
    deleteSubGroup: deleteSubGroup,
    addOption: addOption,
    editOption: editOption,
    deleteOption: deleteOption,
  };

  return (
    <ModalOptionsService.Provider value={context}>
      {children}

      <FormSubGrupo ref={formSubGroupModal} />
      <DeleteSubGroup ref={deleteSubGroupModal} />

      <FormIngredient ref={formIngredientModal} />

      <DeleteIngredient ref={deleteIngredientModal} />
    </ModalOptionsService.Provider>
  );
}
