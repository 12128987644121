const Pagamento = ({ pedido }) => {
  return (
    <>
      <div className="mb-4">
        <h4 className="opacity-5 text-success fsc-1p2">Pagamento</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <>
            <div className="fw-light">
              {pedido.payment === "LOCAL" && (
                <>
                  <div className="fsc-1p2 fsc-m-1">Pagamento na entrega</div>
                  <div>{localPaymentMethods[pedido.payment_method].name}</div>
                </>
              )}

              {pedido.payment === "DIRECT" && (
                <>
                  <div className="fsc-1p2 fsc-m-1">Pagamento pelo site</div>
                  <div>
                    {pedido.payment_method === "credit" && <>Crédito</>}
                    {pedido.payment_method === "pix" && <>Pix</>}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Pagamento;

const localPaymentMethods = {
  dinheiro: {
    name: "Dinheiro",
    image: "/img/pagamento/dinheiro.png",
  },
  master_debito: {
    name: "Mastercard - Débito",
    image: "/img/pagamento/mastercard.png",
  },
  visa_debito: {
    name: "Visa - Débito",
    image: "/img/pagamento/visa.png",
  },
  elo_debito: {
    name: "Elo - Débito",
    image: "/img/pagamento/elo.png",
  },
  visa_credito: {
    name: "Visa - Crédito",
    image: "/img/pagamento/visa.png",
  },
  mastercard_credito: {
    name: "Mastercard - Crédito",
    image: "/img/pagamento/mastercard.png",
  },
  elo_credito: {
    name: "Elo - Crédito",
    image: "/img/pagamento/elo.png",
  },
  amex_credito: {
    name: "Amex - Crédito",
    image: "/img/pagamento/amex.png",
  },
  ticket: {
    name: "Ticket - Vale-Refeição",
    image: "/img/pagamento/ticket.png",
  },
};
