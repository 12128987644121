import { useEffect, useState } from "react";
import Title from "../../../components/Layout/Title/Title";
import { ListServiceProvider } from "../../../components/shared/Lists/ListService";
import http from "../../../misc/classes/HttpRequest/HttpRequest";
import ProdutosLista from "./ProdutosLista";
import SearchList from "../../../components/shared/Lists/SearchList/SearchList";

const Produtos = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    getProdutos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getProdutos() {
    http.get("/produtos", onSuccess);
  }

  function onSuccess(data) {
    setList(data.list);
  }

  return (
    <>
      <Title type="link">Produtos</Title>

      <div className="mt-5"></div>

      <ListServiceProvider
        list={list}
        searchIn={["name", "category_name", "description"]}
      >
        <SearchList />
        <div className="mb-2 mb-lg-4"></div>

        <ProdutosLista />
      </ListServiceProvider>
    </>
  );
};

export default Produtos;
