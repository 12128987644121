import { forwardRef, useContext, useImperativeHandle, useRef } from "react";
import Modal from "../../../../../components/Layout/Modal/Modal";
import ListService from "../../../../../components/shared/Lists/ListService";

const DeleteIngredient = forwardRef((props, ref) => {
    const listService = useContext(ListService);
    const modal = useRef();
    const groupIndexRef = useRef();
    const optIndexRef = useRef();
    
  useImperativeHandle(ref, () => ({
    open
  }));

  function open(groupIndex, optIndex){
    groupIndexRef.current.value = groupIndex;
    optIndexRef.current.value = optIndex;
    modal.current.open();
  }

  function deleteIngredient(){
    const groupIndex = groupIndexRef.current.value;
    const optIndex = optIndexRef.current.value;

    let newList = listService.copy();

    newList[groupIndex].ingredients.splice(optIndex, 1);

    listService.change(newList);
    modal.current.close();
  }

  return (
    <>
      <Modal
        id="deletar-ingrediente"
        isStatic={true}
        title="Deletar opção"
        ref={modal}
      >
        <input className="hidden" type="text" ref={groupIndexRef} />
        <input className="hidden" type="text" ref={optIndexRef} />
        
        <div>Deseja realmente deletar esta opção?</div>

        <div className="row mt-5 justify-content-between">
          <div className="col-auto">
            <div className="btn btn-secondary" data-bs-dismiss="modal">
              Voltar
            </div>
          </div>
          <div className="col-auto">
            <div className="btn btn-danger" onClick={deleteIngredient}>
              Deletar
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default DeleteIngredient;
