import { useState } from "react";
import LoadingPage from "../../../../../components/Layout/LoadingPage";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import FontAwesome from "../../../../../components/Layout/FontAwesome";
import { useEffect } from "react";
import HttpRequest from "../../../../../misc/classes/HttpRequest/HttpRequest";
import Form from "../../../../../components/shared/Facilitators/Form";
import MainInfo from "./MainInfo";
import CustomerInfo from "./CustomerInfo";
import AddressInfo from "./AddressInfo";
import PaymentInfo from "./PaymentInfo";
import ItemsInfo from "./ItemsInfo";
import { useRef } from "react";
import PrintOrder from "../ModalPedido/PrintOrder/PrintOrder";
import Modal from "../../../../../components/Layout/Modal/Modal";
import ButtonLoading from "../../../../../components/Layout/ButtonLoading";

const Pedido = (props) => {
  const { orderId } = useParams();
  const [pedido, setPedido] = useState({ id: 0 });
  const navigate = useNavigate();
  const formRef = useRef();
  const modalDelete = useRef();
  const btnSave = useRef();
  const btnDel = useRef();

  useEffect(() => {
    getPedido();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getPedido() {
    HttpRequest.get("/pedidos/" + orderId, onSuccessGet, onNotFound);
  }

  function onSuccessGet(data) {
    setPedido(data.order);
  }

  function onNotFound() {
    navigate("/pedidos");
  }

  function onSubmit(body) {
    // btnSave.current.setLoading(true);
    
    HttpRequest.post("/pedidos/" + pedido.id, body, onSuccess);
  }

  function onSuccess(response){

  }

  function deleteOrder() {
    modalDelete.current.open();
  }

  function deleteConfirm() {
    btnDel.current.setLoading(true);

    HttpRequest.post("/pedidos/" + pedido.id + "/deletar", null, onDeleted);
  }

  function onDeleted() {
    modalDelete.current.close();
    onNotFound();
  }

  return (
    <>
      <LoadingPage condition={pedido.id > 0}>
        <NavLink to="/pedidos" style={{ textDecoration: "unset" }}>
          <div className="mb-4 text-success opacity-7 text-center text-lg-start">
            <span className="me-2">
              <FontAwesome type="solid" icon="chevron-left" />
            </span>
            Voltar para pedidos
          </div>
        </NavLink>

        <h1>
          <FontAwesome icon="receipt" /> Pedido {pedido.id}
        </h1>

        <Form
          ref={formRef}
          className="box-widget m-n-content-mobile mt-5"
          onSubmit={onSubmit}
          method="PUT"
        >
          <MainInfo pedido={pedido} />

          <hr className="mb-5 mt-4" />

          <CustomerInfo pedido={pedido} />

          <hr className="mb-5 mt-4" />

          <AddressInfo pedido={pedido} />

          <hr className="mb-5 mt-4" />

          <PaymentInfo pedido={pedido} />

          <hr className="mb-5 mt-4" />

          <ItemsInfo pedido={pedido} />

          <div className="row mt-5">
            <div className="col-lg-auto col-12">
              {pedido.id !== null && <PrintOrder pedido={pedido} />}
            </div>
            <div className="col-lg-auto col-12">
              <ButtonLoading className="text-danger" ref={btnSave}>
                <button className="btn btn-primary px-4 py-2 fw-500 text-uppercase w-100">
                  Salvar Alterações
                </button>
              </ButtonLoading>
            </div>
            <div className="col"></div>
            <div className="col-auto">
              <div
                className="btn btn-outline-danger px-4 py-2 fw-500 text-uppercase w-100"
                onClick={deleteOrder}
              >
                Deletar
              </div>
            </div>
          </div>
        </Form>

        <Modal id="delete-order" title="Deletar Pedido" ref={modalDelete}>
          <div>Deseja realmente deletar este pedido?</div>
          <div>Não é possível desfazer esta ação.</div>

          <div className="row justify-content-between mt-5">
            <div className="col-auto">
              <div className="btn btn-secondary" data-bs-dismiss="modal">
                Cancelar
              </div>
            </div>
            <div className="col-auto">
              <ButtonLoading className="text-danger" ref={btnDel}>
                <div className="btn btn-danger" onClick={deleteConfirm}>
                  Deletar
                </div>
              </ButtonLoading>
            </div>
          </div>
        </Modal>
      </LoadingPage>
    </>
  );
};

export default Pedido;
