import FontAwesome from "../../../../../components/Layout/FontAwesome";

const Cliente = ({ pedido }) => {
  return (
    <>
      <div className="mb-4">
        <h4 className="opacity-5 text-success fsc-1p2">Cliente</h4>

        {pedido.id === null && (
          <div className="placeholder-glow w-100">
            <div className="py-4 rounded placeholder bg-success w-100"></div>
          </div>
        )}

        {pedido.id !== null && (
          <>
            <div className="fsc-1p2 fsc-m-1 fw-light">{pedido.name}</div>

            <div className="row fw-light align-items-center justify-content-between my-1">
              <div className="col-auto">
                <span className="opacity-7 me-1 fsc-n9">
                  <FontAwesome type="solid" icon="phone" />
                </span>{" "}
                {pedido.tel_formated}
              </div>
              <div className="col-auto">
                <a
                  href={"https://api.whatsapp.com/send?phone=" + pedido.tel}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-light text-success fsc-n8 py-1 fw-bold"
                >
                  Abrir no Whatsapp
                </a>
              </div>
            </div>
            <div className="fw-light">
              <span className="opacity-7 me-1 fsc-n9">
                <FontAwesome type="solid" icon="address-card" />
              </span>{" "}
              {pedido.document_formated}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cliente;
