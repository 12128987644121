import { forwardRef } from "react";
import Modal from "../../../../../components/Layout/Modal/Modal";
import { useRef } from "react";
import { useContext } from "react";
import ListService from "../../../../../components/shared/Lists/ListService";
import { useImperativeHandle } from "react";
import { useState } from "react";

const FormSubGrupo = forwardRef((props, ref) => {
  const listService = useContext(ListService);

  const [edit, setEdit] = useState(false);

  const groupIndexRef = useRef();
  const nameRef = useRef();
  const requiredRef = useRef();
  const qtdRef = useRef();
  const modal = useRef();

  useImperativeHandle(ref, () => ({
    openNew,
    openEdit,
  }));

  function openNew() {
    setEdit(false);

    setInputValues();

    modal.current.open();
  }

  function openEdit(groupIndex) {
    setEdit(true);

    setInputValues(
      listService.list[groupIndex].name,
      listService.list[groupIndex].quantity,
      listService.list[groupIndex].required,
      groupIndex
    );

    modal.current.open();
  }

  function setInputValues(name = "", quantity = 0, required = '0', group = "") {
    groupIndexRef.current.value = group;
    nameRef.current.value = name;
    requiredRef.current.value = (required) ? required : '0';
    qtdRef.current.value = quantity;
  }

  function addGroup() {
    const name = nameRef.current.value;
    const qtd = qtdRef.current.value;
    const required = requiredRef.current.value;

    let newList = listService.copy();
    newList.push({ name: name, quantity: qtd, ingredients: [], required: required });

    listService.change(newList);
    modal.current.close();
  }

  function editGroup() {
    const groupIndex = groupIndexRef.current.value;
    let newList = listService.copy();

    newList[groupIndex].name = nameRef.current.value;
    newList[groupIndex].quantity = qtdRef.current.value;
    newList[groupIndex].required = requiredRef.current.value;

    listService.change(newList);
    modal.current.close();
  }

  return (
    <>
      <Modal
        id="novo-grupo"
        isStatic={true}
        title={edit ? "Editar subgrupo" : "Novo subgrupo"}
        ref={modal}
      >
        <input type="text" className="hidden" ref={groupIndexRef} />

        <div className="form-group">
          <label htmlFor="name">Nome</label>
          <input
            type="text"
            id="name"
            placeholder="Molhos, Temperos, Ingredientes, etc..."
            className="form-control"
            ref={nameRef}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="required">Obrigatório</label>
          <select id='required' className="form-select" ref={requiredRef}>
            <option value='0'>Não</option>
            <option value='1'>Sim</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="quantity">Quantidade de escolhas</label>
          <div className="row align-items-center">
            <div className="col-auto">Até</div>
            <div className="col">
              <input
                type="number"
                id="quantity"
                inputMode="numeric"
                className="form-control"
                ref={qtdRef}
                defaultValue={0}
              />
            </div>
            <div className="col-auto">opções</div>
          </div>
          <div className="mt-2 fsc-n8">Deixe em zero para número ilimitado</div>
        </div>
        <div className="text-end">
          {!edit && (
            <div className="btn btn-primary" onClick={addGroup}>
              Adicionar
            </div>
          )}
          {edit && (
            <div className="btn btn-primary" onClick={editGroup}>
              Salvar
            </div>
          )}
        </div>
      </Modal>
    </>
  );
});

export default FormSubGrupo;
