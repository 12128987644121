import { useState } from "react";
import LoadingPage from "../../../../components/Layout/LoadingPage";
import NavHorizontal from "../../../../components/Layout/NavHorizontal/NavHorizontal";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import ClienteInformacoes from "./ClienteInformacoes";
import ClientePedidos from "./ClientePedidos";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import FontAwesome from "../../../../components/Layout/FontAwesome";

const Cliente = (props) => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState({ id: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCustomer() {
    HttpRequest.get("/clientes/" + customerId, onSuccessGet, onNotFound);
  }

  function onSuccessGet(data) {
    setCustomer(data.customer);
  }

  function onNotFound() {
    navigate("/clientes");
  }

  return (
    <>
      <LoadingPage condition={customer.id > 0}>
        <NavLink to="/clientes" style={{ textDecoration: "unset" }}>
          <div className="mb-4 text-success opacity-7 text-center text-lg-start">
            <span className="me-2">
              <FontAwesome type="solid" icon="chevron-left" />
            </span>
            Voltar para clientes
          </div>
        </NavLink>

        <h1>{customer.name}</h1>

        <NavHorizontal
          classOuter="m-n-content-mobile mt-4"
          routes={[
            {
              name: "Informações",
              to: "",
              end: true,
            },
            {
              name: "Pedidos",
              to: "pedidos",
            },
          ]}
        />

        <Routes>
          <Route
            path=""
            index={true}
            element={<ClienteInformacoes customer={customer} />}
          />
          <Route path="pedidos" element={<ClientePedidos customer={customerId} />} />
        </Routes>
      </LoadingPage>
    </>
  );
};

export default Cliente;
