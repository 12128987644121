import { useContext } from "react";
import ListService from "../../../components/shared/Lists/ListService";
import CuponsItem from "./CuponsItem";

const CuponsLista = ({onEdit}) => {
  const list = useContext(ListService);

  return (
    <>
      <div className="m-n-content-mobile">
        <div className="box-widget-table">
          <div className="table-responsive">
            <table className="table table-style table-hover table-striped">
              <thead className="fw-bold fsc-n8">
                <tr>
                  <td className="text-center">ID</td>

                  <td className="px-4">NOME</td>

                  <td className="px-4">DESCONTO</td>

                  <td className="px-4">QUANTIDADE</td>

                  <td className="px-4">EXPIRA</td>
                </tr>
              </thead>
              <tbody className="table-group-divider align-middle bg-white border-top">
                {list.list.length > 0 &&
                  list.list.map((cupom, index) => {
                    return (
                        <CuponsItem key={cupom.id} cupom={cupom} onEdit={onEdit} />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CuponsLista;
