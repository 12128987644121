import LoadingPage from "../../../../components/Layout/LoadingPage";
import { ListServiceProvider } from "../../../../components/shared/Lists/ListService";
import HttpRequest from "../../../../misc/classes/HttpRequest/HttpRequest";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProdutoEditInner from "./ProdutoEditInner";

const ProdutoEdit = () => {
    const { productId } = useParams();
  const [produto, setProduto] = useState(dummy_product);
  const navigate = useNavigate();

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getProduct() {
    HttpRequest.get("/produtos/" + productId, onSuccessGet, onNotFound);
  }

  function onSuccessGet(data){
    setProduto(data.product);
  }

  function onNotFound() {
    navigate("/produtos");
  }

  return (
    <>
      <LoadingPage condition={produto.id > 0}>
        <ListServiceProvider list={produto.ingredients}>
          <ProdutoEditInner produto={produto} onSave={getProduct} />
        </ListServiceProvider>
      </LoadingPage>
    </>
  );
};

export default ProdutoEdit;

const dummy_product = {
    id: 0,
    ingredients: []
};