import FontAwesome from "../../../components/Layout/FontAwesome";
import CSS from './ProdutoFoto.module.css';

const ProdutoFoto = ({ photo, ingredient, min }) => {
  return (
    <>
      {photo && (
        <img
          src={process.env.REACT_APP_IMG_URL + (ingredient ? '/ingredientes/' : '/') + (min ? 'min/' : '') + photo}
          alt=""
          className={CSS.photo}
        />
      )}
      {!photo && (
        <div className="fsc-1p5" style={{ color: "var(--bs-gray-300)" }}>
          <FontAwesome icon="camera" type="solid" />
        </div>
      )}
    </>
  );
};

export default ProdutoFoto;
