import { Route, Routes } from "react-router-dom";
import ActivesList from "./ActivesList/ActivesList";
import Pedido from "./Pedido/Pedido/Pedido";
import AllOrders from "./FullList/AllOrders";

const Pedidos = (props) => {
  return (
    <>  
        <Routes>
          <Route path="" index={true} element={<ActivesList />} />
          <Route path="todos" element={<AllOrders />} />
          <Route path=":orderId" element={<Pedido />} />
        </Routes>
    </>
  );
};

export default Pedidos;
