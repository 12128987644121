import { useContext } from "react";
import ButtonLoading from "../../../components/Layout/ButtonLoading";
import ListService from "../../../components/shared/Lists/ListService";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import { useRef } from "react";

const CategoriaSalvarPosicao = (props) => {
  const listService = useContext(ListService);
  const btnLoading = useRef();

  function save() {
    btnLoading.current.setLoading(true);

    HttpRequest.post(
      "/categorias/posicoes",
      JSON.stringify({
        lista: listService.copy(),
      }),
      () => btnLoading.current.setLoading(false),
      () => btnLoading.current.setLoading(false)
    );
  }

  return (
    <ButtonLoading className="text-primary" ref={btnLoading}>
      <div className="btn btn-outline-primary button-bold w-100 rounded-pill px-4 fsc-1p2 fsc-m-1" onClick={save}>
        Salvar Posições
      </div>
    </ButtonLoading>
  );
};

export default CategoriaSalvarPosicao;
