import { useEffect, useRef } from "react";

const BRLInput = ({ name, className, defaultValue, inputProps }) => {
  const realValueRef = useRef();
  const formatedValueRef = useRef();

  useEffect(() => {
    realValueRef.current.value = defaultValue;
    formatBRL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  function setRealValue() {
    let realValue = formatedValueRef.current.value;


    realValue = toInteger(realValue);

    realValue = formatCents(realValue);

    realValueRef.current.value = realValue;
  }

  function formatBRL(){
    const value = realValueRef.current.value;
    formatedValueRef.current.value = numberToCurrency(value);
  }

  function handleTyping() {
    setRealValue();
    formatBRL();
  }

  function formatCents(number){
    number = number.toString();
    number = number.replace('.','');
    const index = number.length - 2;
    const decimal = number.substring(0, index);
    const cents = number.replace(decimal, '');

    let newNumber = decimal+'.'+cents;
    newNumber = parseFloat(newNumber);

    return newNumber;
  }

  function toInteger(number){
    if (number === null || number === "") return 0;

    let price = number.replace(/R\$\s/, "");
    price = price.replace(/\./g, "");
    price = price.replace(/,/g, "");

    return price;
  }

  function numberToCurrency(number) {
    number = parseFloat(number);

    return number.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  return (
    <>
      <input ref={realValueRef} className="hidden" name={name} />
      <input
        ref={formatedValueRef}
        className={className}
        onChange={handleTyping}
        {...inputProps}
        inputMode="decimal"
      />
    </>
  );
};

export default BRLInput;
