import { useEffect } from "react";
import Title from "../../../components/Layout/Title/Title";
import { ListServiceProvider } from "../../../components/shared/Lists/ListService";
import HttpRequest from "../../../misc/classes/HttpRequest/HttpRequest";
import { useRef } from "react";
import { useState } from "react";
import SearchList from "../../../components/shared/Lists/SearchList/SearchList";
import CuponsNova from "./CuponsNova";
import CuponsLista from "./CuponsLista";

const Cupons = (props) => {
  const [list, setList] = useState([]);
  const modalNew = useRef();

  useEffect(() => {
    getCupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCupons() {
    HttpRequest.get("/cupons", onSuccess);
  }

  function onSuccess(data) {
    setList(data.list);
  }

  function openNewModal() {
    modalNew.current.open();
  }

  function openEditModal(cupom){
    modalNew.current.open(cupom);
  }

  return (
    <>
      <Title
        notLink={true}
        type="function"
        id="nova-cupons"
        onClick={openNewModal}
      >
        Cupons
      </Title>

      <CuponsNova ref={modalNew} onSuccess={getCupons} />

      <div className="mt-5"></div>

      <ListServiceProvider list={list} searchIn={["id", "name"]}>
        <SearchList />

        <div className="mb-2 mb-lg-4"></div>

        <CuponsLista onEdit={openEditModal} />
      </ListServiceProvider>
    </>
  );
};

export default Cupons;
