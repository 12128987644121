import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import CSS2 from "../../Templates/Tags.module.css";
import FontAwesome from "../../../../../components/Layout/FontAwesome";

const EntregaFilter = (props) => {
  const [statusChecked, setStatusChecked] = useState(["DELIVERY", "PICKUP"]);
  const inputRef = useRef();

  useEffect(() => {
    writeInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusChecked]);

  function onChanged(status) {
    let newStatusChecked = [...statusChecked];
    const index = statusChecked.indexOf(status);

    if (index > -1) {
      newStatusChecked.splice(index, 1);
    } else {
      newStatusChecked.push(status);
    }

    setStatusChecked(newStatusChecked);
  }

  function writeInput() {
    const statusList =
      statusChecked.length > 0 && statusChecked.length < optionsList.length
        ? statusChecked.join("+")
        : "";

    inputRef.current.value = statusList;
  }

  return (
    <>
      <div className="form-group mb-0">
        <label>Entrega</label>
        <input name="delivery" className="hidden" ref={inputRef} />
        <div className=" p-0 ">
          {optionsList.map((status, index) => {
            return (
              <EntregaFilterOption
                key={index}
                status={status}
                onChanged={onChanged}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EntregaFilter;

const EntregaFilterOption = ({ status, onChanged }) => {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  function toggleChecked() {
    setChecked(!checked);
    onChanged(status.value);
  }

  return (
    <div
      className={
        "px-2 py-1 fsc-n9 opacity-5 d-inline-block border rounded fw-500 cursor-pointer m-1 " +
        (checked && "opacity-9 " + CSS2[status.class])
      }
      onClick={toggleChecked}
    >
      <span className="me-2">
        {checked ? (
          <FontAwesome icon="square-check" />
        ) : (
          <FontAwesome type="regular" icon="square" />
        )}
      </span>
      {status.name}
    </div>
  );
};

const optionsList = [
  { value: "DELIVERY", name: "Entregar", class: "entregar" },
  { value: "PICKUP", name: "Retirar", class: "presencial" },
];
