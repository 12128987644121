import { useRef } from "react";
import FontAwesome from "../../../Layout/FontAwesome";
import CSS from "./Search.module.css";
import { useState } from "react";
import { useEffect } from "react";

const Search = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState(null);
  const [initiated, setInitiated] = useState(false);
  const inputRef = useRef();

  useEffect(()=>{
    if(initiated){
        onSearch(searchValue);
    } else {
        setInitiated(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const resetSearch = () => {
    inputRef.current.value = "";
    setSearchValue(null);
  };

  const submitSearch = () => {
    let value = inputRef.current.value;

    if(value.trim() === ''){
        value = null;
    };

    setSearchValue(value);
  };

  return (
    <>
      <div className={`${CSS.search_list}`}>
        <input
          className="form-control py-3"
          type="text"
          placeholder="Pesquisar"
          ref={inputRef}
        />

        <div className={`${CSS.cancel}`}>
          <button className={`btn btn-primary me-2`} onClick={submitSearch}>
            <FontAwesome icon="magnifying-glass" type="solid" />
          </button>

          <div className={`btn btn-light text-danger`} onClick={resetSearch}>
            <FontAwesome icon="times" type="solid" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
